import $http, { $httpOrigin } from '@/utils/request'
import store from "@/store";
const url = process.env['VUE_APP_BASE_API']
export default {
  //获得下拉框数据
  getBrandName({ bussinessUnitId }) {
    return $http.get('/hrRafForm/getInternalBrandName', {
      params: {
        bussinessUnitId
      }
    })
  },
  //获得下拉框数据
  getOfferStatusList({}) {
    return $http.get('/offerProposal/offerStatus', {
      params: {}
    })
  },
  //获得下拉框数据
  getNationList({}) {
    return $http.get('/baseData/nation', {
      params: {}
    })
  },
  // 获取候选人数据
  getDataList(params) {
    return $http.get('/offerProposal/candidate', {
      params
    })
  },

  // 拒绝入职
  rejectJoin(params) {
    return $http.post('/offerProposal/rejectJoin', params)
  },
  // 发送sendOffer
  sendOffer(params) {
    console.log('787878', params)
    return $http.post('/offerProposal/sendOffer', params)
  },
  // 发送背调
  sendBack(params) {
    return $http.post('/offerProposal/sendBackEmail', params)
  },
  //被调录入
  backEntry(params) {
    return $http.post('/offerProposal/backEntry', params)
  },

  //转入ops
  returnOPs({ ids }) {
    return $http.get('/offerProposal/intoOps', {
      params: {
        ids
      }
    })
  },
  checkRejectJoin(params) {
    return $http.post('offerProposal/checkRejectJoin', params)
  },
  // 获取详情信息
  getHcInfoMsg({ id }) {
    return $http.get('/offerProposal/hiringInfo', {
      params: {
        id
      }
    })
  },
  // 获取candidateInfo
  getCandidateInfo(params) {
    return $http.get('/candidate/candidateInfo', {
      params
    })
  },
  // 修改招聘管理信息
  editCandidate(params) {
    return $http.post('/candidate/edit', params)
  },
  //背调信息
  getBackInfo(params) {
    console.log('8888', params)
    return $http.get('/backInfo/candidateBackInfo', {
      params
    })
  },
  employmentHistoryList(params){
    return $http.get('/candidate/employmentHistoryList', {
      params
    })
  },
  employeeBackInfo(params){
    return $http.get('/backInfo/employeeBackInfo', {
      params
    })
  },

  /**
   * 批量晋升 倒入之后 结果加载 分页
   */
  staffPromotionExcelPpage(params) {
    return $http.post('staffPromotion/excel/page', params)
  },

  /**
   * 上传po附件
   * @param {FormData} formData 可以提交文件的FormData表单对象
   */
  uploadPo(formData) {
    return $http.post(`/fileInfo/candidateUpload`, formData, {
      upload: true,
      timeout: 24 * 60 * 60 * 1000
    })
  },
  /**
   * 上传po附件
   * @param {FormData} formData 可以提交文件的FormData表单对象
   */
  deleteUploadPo(id) {
    return $http.post(`/fileInfo/candidateDeleteFile`, id)
  },
  // 获取上传文件的LISt
  getuploadList(params) {
    return $http.get('/offerProposal/candidateFile', {
      params: {
        ...params
      }
    })
  },

  csImportInfoList(params){
    return $http.get('/candidate/csImportInfoList', {
      params: {
        ...params
      }
    })
  },
  csImportInfoUpdate(params){
    return $http.get('/candidate/csImportInfoUpdate', {
      params: {
        ...params
      }
    })
  },
  // 批量晋升入库
  staffPromotionExcelActive(params){
    return $http.get('/staffPromotion/excel/active', {
      params: {
        ...params
      }
    })
  },
  csErrorCount(){
    return $http.get('/candidate/csErrorCount')
  },
  csTemplateExport(data){
    window.open(`${url}/api/iris/candidate/csTemplateExport?token=${store.getters.token}`,'_blank')
  },
  // 批量晋升模版下载
  promotionTemplateExport(data){
    window.open(`${url}/api/iris/staffPromotion/excel/importTemplate?token=${store.getters.token}`,'_blank')
  },
  csErrorExport(data){
    window.open(`${url}/api/iris/candidate/csErrorExport?token=${store.getters.token}`,'_blank')
  },
  // 批量晋升下载错误原因
  staffPromotionExportWithCheckMessage(groupId){
    window.open(`${url}/api/iris/staffPromotion/excel/exportWithCheckMessage?groupId=${groupId}&token=${store.getters.token}`,'_blank')
  },
  importCsInfo(formData) {
    return $http.post(`/candidate/importCsInfo`, formData, {
      timeout: 24 * 60 * 60 * 1000
    })
  },
  // 批量晋升excel导入
  staffPromotionExcelImport(formData) {
    return $http.post(`/staffPromotion/excel/import`, formData, {
      timeout: 24 * 60 * 60 * 1000
    })
  },
  deImportInfo(params){
    return $http.delete('/candidate/deImportInfo',{
      params: {
        ...params
      }
    })
  },
  // 批量晋升 删除单行数据
  staffPromotionExcelDeleteRow(params){
    return $http.delete('/staffPromotion/excel/deleteRow',{
      params: {
        ...params
      }
    })
  },
  downloadBackInfo(data){
    window.open(`${url}/api/iris/backInfo/downloadBackInfo?id=${data.id}&token=${store.getters.token}`,'_blank')
  },
  deAllImportInfo(){
    return $http.get('/candidate/deAllImportInfo')
  },
}
