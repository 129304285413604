import request from '@/utils/request'
import { exportDownload } from '@/api/export'
import $http from "@/utils/request";

export const rejectReason = (data) => {
  return request({
    url: '/staffPromotion/rejectReason',
    params: {
      ...data
    }
  })
}

export const getEmployeesList = (data) => {
  return request({
    url: '/staff/staffInfo',
    method: 'POST',
    data
  })
}
export const getEmployeesListInfo = (data) => {
  return request({
    // url: '/staff/staffInfo',
    url: '/staff/list',
    method: 'POST',
    data
  })
}
export const staffTemplateDownload = (data) => {
  exportDownload('staff/staffTemplateDownPackage', data)
}
export const exportStaffList = () => {
  exportDownload('staff/exportStaffList', {})
}
// 下载子女信息
export const exportStaffChildrenList = () => {
  exportDownload('children/export', {})
}
// 新增下载全部 Candidate List
export const exportCandidateList = () => {
  exportDownload('/offerProposal/exportCandidateList', {})
}
export const downloadPositiveResult = (data) => {
  exportDownload('hcInformationRegular/downloadPositiveResult', data)
}

export const terminationQuit = (data) => {
  return request({
    url: '/hcInformationLeave/termination',
    method: 'POST',
    data
  })
}
export const hcInformationContractTermination = (data) => {
  return request({
    url: '/hcInformationContract/termination',
    method: 'POST',
    data
  })
}
export const hcInformationContractFinish = (data) => {
  return request({
    url: '/hcInformationContract/finish',
    method: 'POST',
    data
  })
}
export const notified = (data) => {
  return request({
    url: '/hcInformationLeave/notified',
    method: 'POST',
    data
  })
}

export const getPositiveInfo = (data) => {
  return request({
    url: '/hcInformationRegular/getPositiveInfo',
    method: 'POST',
    data
  })
}

export const getLineManageEmailList = (data) => {
  return request({
    url: '/hcInformationRegular/getLineManageEmailList',
    params: {
      ...data
    }
  })
}
export const getOps = (data) => {
  return request({
    url: '/hcInformationContract/getOps',
    params: {
      ...data
    }
  })
}

export const hcInformationContractNotified = (data) => {
  return request({
    url: '/hcInformationContract/notified',
    method: 'POST',
    data
  })
}
export const savePositiveInfo = (data) => {
  return request({
    url: '/hcInformationRegular/savePositiveInfo',
    method: 'POST',
    data
  })
}
export const sendEmailHc = (data) => {
  return request({
    url: '/hcInformationRegular/sendEmail',
    method: 'POST',
    data
  })
}
export const positive = (data) => {
  return request({
    url: 'hcInformationRegular/positive',
    method: 'POST',
    data
  })
}
export const terminate = (data) => {
  return request({
    url: '/hcInformationRegular/termination',
    method: 'POST',
    data
  })
}
export const getUserInfo = (data) => {
  return request({
    url: '/hcInformationTransfer/getUserInfo',
    params: {
      ...data
    }
  })
}
export const getUserInfoLeave = (data) => {
  return request({
    url: '/hcInformationLeave/getUserInfo',
    params: {
      ...data
    }
  })
}
export const termination = (data) => {
  return request({
    url: 'hcInformationTransfer/termination',
    method: 'POST',
    data
  })
}
export const promotionInfo = (data) => {
  return request({
    url: 'staffPromotion/promotionInfo',
    params: {
      ...data
    }
  })
}
export const checkWorkFlow = (data) => {
  return request({
    url: '/hcInformationTransfer/checkWorkFlow',
    method: 'POST',
    data
  })
}
export const editPromotion = (data) => {
  return request({
    url: '/staffPromotion/editPromotion',
    method: 'POST',
    data
  })
}
export const baseDataByCode = (data) => {
  return request({
    url: '/baseData/baseDataByCode',
    params: {
      ...data
    }
  })
}
export const staffPromotionSubmit = (data) => {
  return request({
    url: '/staffPromotion/submit',
    params: {
      ...data
    }
  })
}
export const staffPromotionTerminate = (data) => {
  return request({
    url: '/staffPromotion/terminate',
    params: {
      ...data
    }
  })
}
export const staffInfoCount = (data) => {
  return request({
    url: '/staff/staffInfoCount',
    params: {
      ...data
    }
  })
}
export const staffPromotionPass = (data) => {
  return request({
    url: '/staffPromotion/pass',
    params: {
      ...data
    }
  })
}
export const turnDown = (data) => {
  return request({
    url: '/staffPromotion/turnDown',
    params: {
      ...data
    }
  })
}
export const uploadPromotionPassFile = (data) => {
  return request({
    url: '/staffPromotion/uploadPromotionPassFile',
    method: 'POST',
    data
  })
}
export const getTransInfoByHcId = (data) => {
  return request({
    url: '/hcInformationTransfer/getTransInfoByHcId',
    params: {
      ...data
    }
  })
}
export const hcInformationContractRenew = (data) => {
  return request({
    url: '/hcInformationContract/renew',
    method: 'POST',
    data
  })
}
export const getRenewInfo = (data) => {
  return request({
    url: '/hcInformationContract/getRenewInfo',
    params: {
      ...data
    }
  })
}

export const hcInformationContractSendEmail = (data) => {
  return request({
    url: '/hcInformationContract/sendEmail',
    method: 'POST',
    data
  })
}
export const hcInformationContractApprove = (data) => {
  return request({
    url: '/hcInformationContract/approve',
    method: 'POST',
    data
  })
}
export const hcInformationContractReject = (data) => {
  return request({
    url: '/hcInformationContract/reject',
    method: 'POST',
    data
  })
}
export const downLoadChangeList = (data) => {
  return $http({
    url: '/staff/downLoad/change_list',
    method: 'POST',
    responseType:'arraybuffer',
    data
  })
}
export const generateQuota = (data) => {
  return $http({
    url: '/candidate/createHc',
    method: 'POST',
    data,
    params: {
      ...data
    }
  })
}
