import { login } from '@/api/sys'
import md5 from 'md5'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { TOKEN, USERINFO } from '@/constant'
import router, { resetRouter } from '@/router'
// import store from '@/store'
// import { setTimeStamp } from '@/utils/auth'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {},
    userRole: getItem('userRole') || ''
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
      setItem(USERINFO, userInfo)
    },
    setUserRole(state, userRole) {
      state.userRole = userRole
      setItem('userRole', userRole)
    }
  },
  actions: {
    login(context, userInfo) {
      const Salt = '123ABCdef*'
      const { username, password, captcha } = userInfo
      return new Promise((resolve, reject) => {
        login({
          username,
          password: md5(password + Salt),
          captcha
        })
          .then(async (data) => {
            this.commit('user/setToken', data.data.accessToken)
            this.commit('user/setUserRole', data.data.userType)
            setItem(USERINFO, data.data.menuList)
            setItem('userName', data.data.username)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async getUserInfo(context) {
      const res = getItem(USERINFO)
      this.commit('user/setUserInfo', res)
      return res
    },
    logout() {
      resetRouter()
      this.commit('user/setToken', '')
      this.commit('user/setUserInfo', [])
      removeAllItem()
      router.replace({
        path: '/login'
      })
    }
  }
}
