import { getItem } from '@/utils/storage'
import { othersData } from '@/views/salaryManagement/editionData/addColumnConfig/othersData'

export const severanceData = [
  {
    key: 'severanceOp141Jan',
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'severanceOp141Feb',
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'severanceOp141Mar',
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'severanceOp141Apr',
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'severanceOp141May',
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'severanceOp141Jun',
    name: 'Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'severanceOp141Jul',
    name: 'Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'severanceOp141Aug',
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'severanceOp141Sep',
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'severanceOp141Oct',
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'severanceOp141Nov',
    name: 'Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'severanceOp141Dec',
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  }
]
export const changeSeveranceDataYear = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    severanceData.forEach((item) => {
      let name = item.name.split('-')[0]
      item.name = name + '-' + year
    })
  }
}
severanceData.map((v) => {
  v.name = 'Severance ' + v.name
})
