import request from '@/utils/request'
import { exportDownload } from '@/api/export'
import $http from "@/utils/request";

// 正式员工
export const getLevel1List = () => {
  return request({
    url: '/hrRafForm/getLevel1  '
  })
}

export const getLevel2List = (data) => {
  return request({
    url: '/hrRafForm/getLevel2ByLevel1Id?level1Id=' + data
  })
}

export const getFTEList = (data) => {
  return request({
    url: '/itData/fteList',
    method: 'POST',
    data
  })
}

export const getFTECount = () => {
  return request({
    url: '/itData/fteCount',
    method: 'GET',
  })
}

export const exportFTEList = () => {
  exportDownload('itData/downLoadFTEList', {})
}
export const downLoadStaffListByStatus = (params) => {
  return $http({
    method:'POST',
    url:'itData/downLoadStaffListByStatus',
    responseType: 'arraybuffer',
    data:params
  })
}
// 实习生
export const exportInternList = () => {
  exportDownload('itData/downLoadInternList', {})
}
export const downLoadInternListByStatus = (params) => {
  return $http({
    method:'POST',
    url:'itData/downLoadInternListByStatus',
    responseType: 'arraybuffer',
    data:params
  })
}
export const getInternList = (data) => {
  return request({
    url: '/itData/internList',
    method: 'POST',
    data
  })
}

export const getInternCount = () => {
  return request({
    url: '/itData/internCount',
    method: 'GET',
  })
}
