import layout from '@/layout'

export default {
  path: '/itData',
  component: layout,
  redirect: '/itData/employee',
  name: 'itData',
  meta: {
    title: 'itData',
    icon: 'setting'
  },
  children: [
    {
      path: '/itData/itFTE',
      name: 'itEmployee',
      component: () => import('@/views/IT_Data/employee.vue'),
      meta: {
        title: 'itEmployee',
        icon: '#'
      }
    },
    {
      path: '/itData/itIntern',
      name: 'itIntern',
      component: () => import('@/views/IT_Data/intern.vue'),
      meta: {
        title: 'itIntern',
        icon: '#'
      }
    },
  ]
}