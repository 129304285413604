<template>
  <el-menu
    :default-active="activeMenu"
    class="menu-list"
    :collapse="!$store.getters.sidebarOpened"
    unique-opened
    router
  >
    <sidebar-item
      v-for="item in routes"
      :key="item.path"
      :route="item"
    ></sidebar-item>
  </el-menu>
</template>

<script>
import { filterRouters, generateMenus } from '@/utils/route'
import SidebarItem from './SidebarItem'
import { setItem } from '@/utils/storage'
import { POINTLIST } from '@/constant'
import { deepClone } from '@/utils/util'

export default {
  name: 'SidebarMenu',
  components: {
    SidebarItem
  },
  computed: {
    routes() {
      const mapRoutes = [...this.$router.getRoutes()]
      if (mapRoutes && mapRoutes.length) {
        mapRoutes.forEach((paramRoute) => {
          if (paramRoute.parent) {
            const path = paramRoute.parent.path
            const childRoute = mapRoutes.find(
              (item) => item.path && item.path === path
            )
            try {
              if (childRoute) {
                if (childRoute.children && childRoute.children.length) {
                  childRoute.children.push(deepClone(paramRoute))
                } else {
                  childRoute.children = []
                  childRoute.children.push(deepClone(paramRoute))
                }
              }
            } catch {
              return new Error('路由异常')
            }
          }
        })
        /*
          循环找出 路由中有一个子节点的 提出来，显示一级菜单
        */
        // mapRoutes.forEach((item, index) => {
        //   if (item.children && item.children.length === 1) {
        //     mapRoutes.push(deepClone(item.children[0]))
        //     mapRoutes.splice(index, 1)
        //   }
        // })
        const filterRoutes = filterRouters(mapRoutes)
        return generateMenus(filterRoutes)
      } else {
        return []
      }
    },
    activeMenu() {
      const { meta, path } = this.$route
      if (meta.pointList) {
        this.$store.dispatch('permission/savePont', meta.pointList)
        setItem(POINTLIST, meta.pointList)
      }

      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-list {
  width: 240px;
  //height: calc(100vh - 60px - 32px);
  border-right: 0 !important;
  &.el-menu--collapse {
    width: 58px;
  }
}
::v-deep .el-menu-item {
  padding: 0 0;
}
.sidebarOpenedItem {
  padding-top: 20px;
}
::v-deep .sidebarOpened .el-submenu__title {
  padding: 20px 0 0 0;
}
::v-deep .el-submenu {
  text-align: left;
  .el-menu {
    background: #f4f4f4;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #828282;
  }
  &.is-active .el-submenu__title {
    color: $--color-primary;
  }
  .el-menu-item.is-active {
    background: $poc-color-primary-deputy;
    border-right: 4px solid $--color-primary;
    a {
      color: $--color-primary;
    }
  }
  .el-menu-item-group {
    background: $base-bg-color;
  }
}
.poc-size14 {
  font-size: 20px !important;
}
</style>
