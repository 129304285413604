export const candidateManage = [
  {
    path: '/candidateManage/backMsg',
    name: 'backMsg',
    component: () => import('@/views/candidateManage/backMsg'),
    meta: {
      title: '背调报告'
    }
  }
]
