import layout from '@/layout'

export default {
  path: '/dashboard/capacityManagement',
  component: layout,
  redirect: '/dashboard/capacitymgt/capacityProject',
  name: 'capacityManagement',
  meta: {
    title: 'capacityManagement',
    icon: 'people'
  },
  children: [
    {
      path: '/dashboard/capacitymgt/capacityProject',
      name: 'capacityProject',
      // component: () => import('@/views/Dashboard/Capacity/capacityProject.vue'),
      meta: {
        title: 'capacityProject',
        icon: '#'
      }
    },
    {
      path: '/dashboard/capacitymgt/personnelTime',
      name: 'capacity',
      // component: () => import('@/views/Dashboard/Capacity/personnelTime.vue'),
      meta: {
        title: 'capacity',
        icon: '#'
      }
    }
  ]
}