import { LANG } from '@/constant'
import { getItem, setItem } from '@/utils/storage'
import { check, typeRange } from '@/utils/util'
import { getFTECount, getInternCount } from '@/api/itData'
export default {
  namespaced: true,
  state: () => ({
    // staffInfoCount: {
    //   onTheJobCount: getItem('onTheJobCount') || 0,
    // }
    fteCount1: getItem('fteCount1') || 0, // 在职
    fteCount2: getItem('fteCount2') || 0, // 离职
    fteCount3: getItem('fteCount3') || 0, // 待入职
    internCount1: getItem('internCount1') || 0,
    internCount2: getItem('internCount2') || 0,
    internCount3: getItem('internCount3') || 0,
  }),
  mutations: {
    setFTECount(state, obj) {
      // if (obj && check(obj) === typeRange.object) {
      //   Object.keys(state.staffInfoCount).forEach((item) => {
      //     setItem(item, obj[item])
      //     state.staffInfoCount[item] = obj[item]
      //   })
      // }
      if (obj && check(obj) === typeRange.array) {
        console.log(obj)
        obj.forEach((item) => {
          if(item.status === '1' ){
            setItem('fteCount1', item.count)
            state.fteCount1 = item.count
          }else if(item.status === '2' ){
            setItem('fteCount2', item.count)
            state.fteCount2 = item.count
          }else if (item.status === '3'){
            setItem('fteCount3', item.count)
            state.fteCount3 = item.count
          }
        })
      }
    },
    setInternCount(state, obj) {
      // if (obj && check(obj) === typeRange.object) {
      //   Object.keys(state.staffInfoCount).forEach((item) => {
      //     setItem(item, obj[item])
      //     state.staffInfoCount[item] = obj[item]
      //   })
      // }
      if (obj && check(obj) === typeRange.array) {
        console.log(obj)
        obj.forEach((item) => {
          if(item.status === '1' ){
            setItem('internCount1', item.count)
            state.internCount1 = item.count
          }else if(item.status === '2' ){
            setItem('internCount2', item.count)
            state.internCount2 = item.count
          }else if (item.status === '3'){
            setItem('internCount3', item.count)
            state.internCount3 = item.count
          }
        })
      }
    }
  },
  actions: {
    getFTECount(context, payload) {
      getFTECount().then((res) => {
        if (res && res.code === 200) {
          context.commit('setFTECount', res.data)
        }
      })
    },
    getInternCount(context, payload) {
      getInternCount().then((res) => {
        console.log(res)
        if (res && res.code === 200) {
          context.commit('setInternCount', res.data)
        }
      })
    }
  }
}
