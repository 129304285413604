import system from '@/router/privateModule/system.js'
import baseSetting from '@/router/privateModule/baseSetting'
import employmentApplication from '@/router/privateModule/employmentApplication'
import intern from '@/router/privateModule/intern.js'
import employees from '@/router/privateModule/employee'
import recruitmentManagement from '@/router/privateModule/RecruitmentManagement'
import itData from '@/router/privateModule/itData'
// import pcTracker from '@/router/privateModule/pcTracker'
import salaryManagement from '@/router/privateModule/salaryManagement'
import pcMgt from '@/router/privateModule/pcMgt'
import capacityMgt from '@/router/privateModule/capacityMgt'

export const privateRoutes = [
  system,
  baseSetting,
  employmentApplication,
  employees,
  employmentApplication,
  intern,
  recruitmentManagement,
  // pcTracker,
  salaryManagement,
  itData,
  pcMgt,
  capacityMgt,
]