import layout from '@/layout'

export default {
  path: '/pcTracker',
  component: layout,
  redirect: '/pcTracker/pcTracker',
  name: 'salaryManagement',
  meta: {
    title: 'salaryManagement',
    icon: 'pctracker'
  },
  children: [
    {
      path: '/pcTracker/pcTracker',
      name: 'pcTracker',
      component: () => import('@/views/pcTrackerTrends/pcTrackerTrends.vue'),
      meta: {
        title: 'pcTrackerManagemengt',
        icon: 'useri'
      }
    },
    {
      path: '/pcTracker/payroll',
      name: 'payroll',
      component: () => import('@/views/payroll/payroll.vue'),
      meta: {
        title: 'payRollManagement',
        icon: 'useri'
      }
    },
    {
      path: '/pcTracker/PCTrackerMVP',
      name: 'PCTrackerMVP',
      component: () => import('@/views/pcTrackerMVP/pcTrackerMVP.vue'),
      meta: {
        title: 'PCTrackerMVPManagement',
        icon: 'useri'
      }
    },
    {
      path: '/pcTracker/ICCharge',
      name: 'IC Charge',
      component: () => import('@/views/IC_Charge/IC_Charge.vue'),
      meta: {
        title: 'IC_Charge',
        icon: 'useri'
      }
    }
  ]
}
