import layout from '@/layout'

export default {
  path: '/intern',
  component: layout,
  redirect: '/intern/internManage',
  name: 'intern',
  meta: {
    title: 'intern',
    icon: 'intern'
  },
  children: [
    {
      path: '/intern/internManage',
      name: 'internManage',
      component: () => import('@/views/intern/internManage.vue'),
      meta: {
        title: 'internManage',
        icon: '#'
      }
    },
    {
      path: '/intern/attendanceRecord',
      name: 'attendanceRecord',
      component: () => import('@/views/attendanceRecord/attendanceRecord.vue'),
      meta: {
        title: 'attendanceRecord',
        icon: '#'
      }
    },
    {
      path: '/intern/attendanceSummary',
      name: 'attendanceSummary',
      component: () =>
        import('@/views/attendanceSummary/attendanceSummary.vue'),
      meta: {
        title: 'attendanceSummary',
        icon: '#'
      }
    }
  ]
}
