import user from '@/store/modules/user'
import $http, { $httpOrigin } from '@/utils/request'
import { exportDownload } from '@/api/export'

export default {
  // 新增应用
  addFunds(params) {
    return $http.post('/social-security-base/add', params)
  },
  // 导出日志
  exportDataList({ effectiveDate, status, name }) {
    return $http.get('/social-security-base-topic/export', {
      isEscape: true,
      params: {
        effectiveDate,
        status,
        name
      }
    })
  },
  /**
   * 上传po附件
   * @param {FormData} formData 可以提交文件的FormData表单对象
   */
  uploadPo(params) {
    return $http.post(`/social-security-base/importExcel`, params, {
      upload: true,
      timeout: 24 * 60 * 60 * 1000
    })
  },
  exportDataList2(data) {
    exportDownload('/social-security-base-topic/export', data)

    // window.location = `/api/iris/social-security-base-topic/export?effectiveDate=${effectiveDate}&status=${status}&name=${name}&token=${localStorage.iriseToken}`
  },
  exportDataList3(data) {
    exportDownload('/orgOrganizations/export', data)

    // window.location = `/api/iris/social-security-base-topic/export?effectiveDate=${effectiveDate}&status=${status}&name=${name}&token=${localStorage.iriseToken}`
  },

  // 获取公积金列表
  getDataList({ effectiveDate, status, name, pageNum, pageSize }) {
    return $http.get('/social-security-base-topic/list', {
      params: {
        effectiveDate,
        status,
        name,
        pageNum,
        pageSize
      }
    })
  },
  // 获取分配组织明细列表
  getOrgList({ id, pageNum, pageSize }) {
    console.log('------------', id)
    return $http.get('/social-security-base-org/organizationsList', {
      params: {
        id,
        pageNum,
        pageSize
      }
    })
  },
  // 获取公积金织明细列表
  getDetailList({ topicId, pageNum, pageSize }) {
    return $http.get('/social-security-base/list', {
      params: {
        topicId,
        pageNum,
        pageSize
      }
    })
  },

  // 获取公积金待分配列表
  getorgListUn(id) {
    return $http.get('/social-security-base-org/orgListUn', {
      params: {
        id
      }
    })
  },
  // 获取公积金已分配列表
  orgList(id) {
    console.log('89888888888', id)
    return $http.get('/social-security-base-org/orgList', {
      params: {
        id
      }
    })
  },

  // 删除
  deleteRow(id) {
    return $http.post('/social-security-base-topic/del', id)
  },
  getWorkCity() {
    return $http.get('/orgOrganizations/getWorkCity')
  }
}
