import user from '@/store/modules/user'
import $http, { $httpOrigin } from '@/utils/request'

export default {
  // 提交功能
  submitBP(params) {
    return $http.get('/offerProposal/submit', { params })
  },
  // 收回功能
  back(params) {
    return $http.get('/offerProposal/takeBack', {
      params
    })
  },
  //分配功能
  assign(params) {
    return $http.get('/offerProposal/distribute', { params })
  },
  // 修改招聘管理信息
  editInduction(params) {
    return $http.post('/offerProposal/edit', params)
  },

  // 获取getHandList
  getDataList(params) {
    return $http.get('/offerProposal/recruitList', {
      params
    })
  },
  // 获取getHandList
  getHandList({}) {
    return $http.get('/offerProposal/inHandList', {
      params: {}
    })
  },

  offerPreview(params){
    return $http.post('/offerProposal/offer/preview', params)
  },

  // 获取getdepartmentAndBusinessList
  getdepartmentAndBusinessList({}) {
    return $http.get('/publicisCsDepartment/departmentAndBusiness', {
      params: {}
    })
  },
  buList({}) {
    return $http.get('/dynamicPctracker/buList', {
      params: {}
    })
  },
  getDescriptionLevel3({}) {
    return $http.get('/hrRafForm/getDescriptionLevel3', {
      params: {}
    })
  },
  getAssigmentProjectLevel2({}) {
    return $http.get('/hrRafForm/getAssigmentProjectLevel2', {
      params: {}
    })
  },
  getLevel3(params) {
    return $http.get('/hrRafForm/getLevel3', {
      params: params
    })
  },
  getLocation({}) {
    return $http.get('/officeLocation/office', {
      params: {}
    })
  },

  getTaAssignPeople(params) {
    console.log('90901', params)
    return $http.get('/hcInformation/getTaUserList', {
      params
    })
  },

  //获得分配的人
  getAssignPeople(params) {
    console.log('90900', params)
    return $http.get('/hcInformation/getUserList', {
      params
    })
  },
  getClientData({}) {
    return $http.get('/hrRafForm/getClient', {
      params: {}
    })
  },
  getEntityHfmCode({}) {
    return $http.get('/hrRafForm/getEntityHfmCode', {
      params: {}
    })
  },
  getHfmCode({}) {
    return $http.get('/sysUser/getHfmCodeList', {
      params: {}
    })
  },
  getBusinessUnitName({}) {
    return $http.get('/hrRafForm/getBusinessUnitNameByHfmCode', {
      params: {}
    })
  },

  //  //获得分配的人
  //  getAssignPeople(params) {
  //   return $http.get('/offerProposal/getTaUserList', {
  //     params
  //   })
  // },

  // 获取详情信息
  getHcInfoMsg({ id }) {
    return $http.get('/offerProposal/hiringInfo', {
      params: {
        id
      }
    })
  },
  // 获取编辑详情信息
  getOfferProposal(params) {
    console.log('参数', params)
    return $http.get('/offerProposal/offerProposal', {
      params
    })
  },
  // 获取编辑页面下拉框信息
  getOfferProposalSelectedData({}) {
    return $http.get('/offerProposal/offerDownBoxData', {
      params: {}
    })
  },
  // 发送更新子女邮件 /children/sendModifyRequestEmail
  getChildrenSendModifyRequestEmail(params) {
    return $http.get('/children/sendModifyRequestEmail', {
      params
    })
  },

  // 基础数据-code_schema查询
  dictList(params) {
    return $http.get('/baseData/dictList', {
      params
    })
  },
  // 获取getCountryList
  getCountry({}) {
    return $http.get('/hrRafForm/getCountry', {
      params: {}
    })
  },
  // 获取getHRBPt
  getHRBP({ id }) {
    return $http.get('/offerProposal/hrbp', {
      params: {
        id
      }
    })
  },

  // 获取单个用户信息
  getSingleMsg({ id }) {
    return $http.get('/sysUser/get', {
      params: {
        id
      }
    })
  },

  //修改入职时间
  editJoinDate(params) {
    return $http.post('/offerProposal/editJoinDate', params)
  },
  // 获取权限信息列表
  getscopeList() {
    return $http.get('/appInfo/getAppList', {
      params: {}
    })
  },
  // 删除排期
  deleteRow(ids) {
    return $http.post('/sysUser/delete', ids)
  },
  // 获取媒体类型
  getMediatTypeList({ keyword, userid }) {
    return $http.get('/DataSource/GetMediatTypeList', {
      params: {
        userid,
        pageNum: 1,
        pageSize: 1000,
        keyword
      }
    })
  },
  // 获取排期状态
  getProcessNodes({ processid }) {
    return $http.get('/DataSource/GetProcessNodes', {
      params: {
        processid
      }
    })
  },
  // 排期已有po查询
  getPOListFromOrderId({ Filter, pageNum, pageSize }) {
    return $http.post('/Order/GetPOListFromOrderId', {
      Filter,
      pageNum,
      pageSize
    })
  },
  // 排期已有po查询
  getSelectPOList({ Order, Filter, pageNum, pageSize }) {
    return $http.post('/Order/SelectPOList', {
      Order,
      Filter,
      pageNum,
      pageSize
    })
  },
  /**
   * 上传po附件
   * @param {FormData} formData 可以提交文件的FormData表单对象
   */
  uploadPo(formData) {
    return $http.post('/Attachment/Upload', formData, {
      upload: true,
      timeout: 24 * 60 * 60 * 1000
    })
  },
  // 保存po
  savePo(poform) {
    return $http.post('/Order/SavePO', { ...poform })
  },
  // 获取完整路径
  getPreviewUrl({ filePath }) {
    return $http.get('/Attachment/GetDowonloadUrl', {
      params: {
        filePath
      }
    })
  },
  // 获取排期管理(待办、已处理、我的、所有)总数
  getOrderListTotal() {
    return $http.get('/Order/GetOrderListTotal')
  },
  // 排期po保存
  orderPOSave({ OrderId, PoList }) {
    return $http.post('/Order/OrderPOSave', { OrderId, PoList })
  },
  resetPasswod(poform) {
    return $http.post('/sysUser/resetPasswod', { ...poform })
  },
  checkRejoin(data) {
    return $http.get('/offerProposal/checkRejoin', {
      params: {
        ...data
      }
    })
  },
  getAnnualLeaveDays(params) {
    return $http.get(`/offerProposal/getAnnualLeaveDays`,{ params:params})
  },
  getNoticePeriod(params) {
    return $http.get(`/offerProposal/getNoticePeriod`, { params:params})
  },
  getNoticePeriodByLevel2AndJobGrading(params) {
    return $http.get(`/offerProposal/getNoticePeriodByLevel2AndJobGrading`, { params:params})
  },
  getOfferStatusList() {
    return $http.get(`/offerProposal/getOfferStausList`)
  },
  turnDownList(params){
    return $http.post('/offer/turn-down/pageList', { ...params })
  },
  editReason(params){
    return $http.post('/offer/turn-down/editReason', { ...params })
  },
  downLoadList(params){
    return $http({
      method:'POST',
      url:'/offer/turn-down/downLoad/list',
      responseType: 'arraybuffer',
      data:params
    })
  },
}
