import { getItem } from '@/utils/storage'
import { basePayRollData } from '@/views/salaryManagement/editionData/addColumnConfig/basePayRollData'

export const ops131CostTableDataConfig = [

  // {
  //   key: 'assigmentProjectLevel2',
  //   name: 'Assigment/project(Level2)',
  //   dataType: 'string',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'left',
  //   width: 200 // 默认宽度，像素
  // },
  // {
  //   key: 'hfmCode',
  //   name: 'HFM Code',
  //   dataType: 'string',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'left',
  //   width: 120 // 默认宽度，像素
  // },
  {
    key: 'chargeToBpName',
    name: 'To HRBP',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'chargeToHfm',
    name: 'To HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'chargeToEntity',
    name: 'To Legal Entity',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  {
    key: 'englishName',
    name: 'English Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'jobTitle',
    name: 'Job Title',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'toCeoName',
    name: 'CEO',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'description',
    name: 'Description',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  // {
  //   key: 'totalCost',
  //   name: 'Total',
  //   isYear: true,
  //   isTotal:true,
  //   dataType: 'number',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'right',
  //   width: 100 // 默认宽度，像素
  // },
  {
    key: 'janCost',
    isYear: true,
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'febCost',
    isYear: true,
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'marCost',
    isYear: true,
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'aprCost',
    isYear: true,
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'mayCost',
    isYear: true,
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'junCost',
    name: 'Jun-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'julCost',
    name: 'Jul-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'augCost',
    isYear: true,
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'sepCost',
    isYear: true,
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'octCost',
    isYear: true,
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'novCost',
    isYear: true,
    name: 'NoV-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'decCost',
    isYear: true,
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  }
]

export const ops131HcTableDataConfig = [

  // {
  //   key: 'assigmentProjectLevel2',
  //   name: 'Assigment/project(Level2)',
  //   dataType: 'string',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'left',
  //   width: 200 // 默认宽度，像素
  // },
  // {
  //   key: 'hfmCode',
  //   name: 'HFM Code',
  //   dataType: 'string',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'left',
  //   width: 120 // 默认宽度，像素
  // },
  {
    key: 'chargeToBpName',
    name: 'To HRBP',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: true, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'chargeToHfm',
    name: 'To HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: 'left', // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'chargeToEntity',
    name: 'To Legal Entity',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 200 // 默认宽度，像素
  },
  {
    key: 'englishName',
    name: 'English Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'jobTitle',
    name: 'Job Title',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'toCeoName',
    name: 'CEO',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'description',
    name: 'Description',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  // {
  //   key: 'totalHc',
  //   name: 'Total',
  //   isYear: true,
  //   isTotal:true,
  //   dataType: 'number',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'right',
  //   width: 100 // 默认宽度，像素
  // },
  {
    key: 'janHc',
    name: 'Jan-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'febHc',
    name: 'Feb-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'marHc',
    name: 'Mar-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'aprHc',
    name: 'Apr-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'mayHc',
    name: 'May-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'junHc',
    name: 'Jun-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'julHc',
    name: 'Jul-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'augHc',
    name: 'Aug-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'sepHc',
    name: 'Sep-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'octHc',
    name: 'Oct-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'novHc',
    name: 'NoV-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'decHc',
    name: 'Dec-',
    isYear: true,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  }
]


export const changeOp131Year = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    ops131CostTableDataConfig.forEach((item) => {
      if (item.isYear) {
        if(item.isTotal){
          item.name = year + ' Total'
        }else {
          let name = item.name.split('-')[0]
          item.name = name + '-' + year
        }
      }
    })
    ops131HcTableDataConfig.forEach((item) => {
      if (item.isYear) {
        if(item.isTotal){
          item.name = year + ' Total'
        }else {
          let name = item.name.split('-')[0]
          item.name = name + '-' + year
        }
      }
    })
  }
}
export const data131 = [
  {
    jobTitle: 'name1',
    assigmentProjectLevel2Name: 23,
    descriptionLevel3Name: 33,
    client: 1
  },
  {
    jobTitle: 'name2',
    assigmentProjectLevel2Name: 2,
    descriptionLevel3Name: 33,
    client: 1
  },
  {
    jobTitle: 'name3',
    assigmentProjectLevel2Name: 23,
    descriptionLevel3Name: 33,
    client: 1
  }
]
