export const pcTracker = [
  {
    path: '/pcTracker/actDataDetail',
    name: 'actualSalary',
    component: () => import('@/views/payroll/components/actDataDetail.vue'),
    meta: {
      title: '详情',
      hidden: true,
      activeMenu: '/pcTracker/payroll'
    }
  },

  //payrollDetail

  {
    path: '/pcTracker/payrollDetail',
    name: 'payrollDetail',
    component: () =>
      import('@/views/payroll/components/payrollDetailReport.vue'),
    meta: {
      title: '详情',
      hidden: true,
      activeMenu: '/pcTracker/payroll'
    }
  },

  {
    path: '/pcTracker/allowanceDetail',
    name: 'payrollDetail',
    component: () =>
      import('@/views/payroll/components/allowanceDetailReport.vue'),
    meta: {
      title: '详情',
      hidden: true,
      activeMenu: '/pcTracker/payroll'
    }
  },

  {
    path: '/pcTracker/annualLeaveDetail',
    name: 'payrollDetail',
    component: () =>
      import('@/views/payroll/components/annualLeaveDetailReport.vue'),
    meta: {
      title: '详情',
      hidden: true,
      activeMenu: '/pcTracker/payroll'
    }
  },

  {
    path: '/pcTracker/FETPaymentDetail',
    name: 'FETPaymentDetail',
    component: () =>
      import('@/views/payroll/components/FTEPaymentDetailReport.vue'),
    meta: {
      title: '详情',
      hidden: true,
      activeMenu: '/pcTracker/payroll'
    }
  },

  {
    path: '/pcTracker/contractorPaymentDetail',
    name: 'FETPaymentDetail',
    component: () =>
      import('@/views/payroll/components/contractorPaymentDetail.vue'),
    meta: {
      title: '详情',
      hidden: true,
      activeMenu: '/pcTracker/payroll'
    }
  },

  {
    path: '/pcTracker/freelancePaymentDetail',
    name: 'FETPaymentDetail',
    component: () =>
      import('@/views/payroll/components/freelancePaymentDetail.vue'),
    meta: {
      title: '详情',
      hidden: true,
      activeMenu: '/pcTracker/payroll'
    }
  },

  //payrollDetailReport
  {
    path: '/pcTracker/internPaymentDetail',
    name: 'FETPaymentDetail',
    component: () =>
      import('@/views/payroll/components/internPaymentDetail.vue'),
    meta: {
      title: '详情',
      hidden: true,
      activeMenu: '/pcTracker/payroll'
    }
  },
]
