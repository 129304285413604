import { addNameData } from '@/views/salaryManagement/editionData/addColumnConfig/addNameData'

const getEnglishName = () => {
  return {
    column: addNameData.map((item) => {
      return {
        ...item,
        visible: false
      }
    }),
    names: addNameData.map((item) => item.key)
  }
}
import { addDateData } from '@/views/salaryManagement/editionData/addColumnConfig/addDateData'

const getStartData = () => {
  return {
    column: addDateData.map((item) => {
      return {
        ...item,
        visible: false
      }
    }),
    names: addDateData.map((item) => item.key)
  }
}
import {
  changeHCDataYear,
  HCData
} from '@/views/salaryManagement/editionData/addColumnConfig/HCData'

const getHCTotal = () => {
  changeHCDataYear()
  return {
    column: HCData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: HCData.map((item) => item.key)
  }
}
import {
  basePayRollData,
  changeBasePayRollDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/basePayRollData'

const getSubTotalBasePayroll = () => {
  changeBasePayRollDataYear()
  return {
    column: basePayRollData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: basePayRollData.map((item) => item.key)
  }
}
import {
  thirteenSalaryData,
  changeThirteenSalaryDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/thirteenSalaryData'

const getSubTotal13thPayroll = () => {
  changeThirteenSalaryDataYear()
  return {
    column: thirteenSalaryData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: thirteenSalaryData.map((item) => item.key)
  }
}
import {
  holidayPayData,
  changeHolidayPayDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/holidayPayData'

const getSubTotalHolidayPay = () => {
  changeHolidayPayDataYear()
  return {
    column: holidayPayData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: holidayPayData.map((item) => item.key)
  }
}
import {
  salesCommData,
  changeSalesCommDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/salesCommData'

const getSubTotalSalesCommOp113 = () => {
  changeSalesCommDataYear()
  return {
    column: salesCommData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: salesCommData.map((item) => item.key)
  }
}
import {
  socialChargesData,
  changeSocialChargesDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/socialChargesData'
const getSubTotalSocialChargesBase = () => {
  changeSocialChargesDataYear()
  return {
    column: socialChargesData.map((item) => {
      return {
        ...item,
        visible: false
      }
    }),
    names: socialChargesData.map((item) => item.key)
  }
}
import {
  socialChargesOp121Data,
  changeSocialChargesOp121DataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/socialCharges121Data'

const getSubTotalSocialChargesOp121 = () => {
  changeSocialChargesOp121DataYear()
  return {
    column: socialChargesOp121Data.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: socialChargesOp121Data.map((item) => item.key)
  }
}
import {
  medicalInsuranceData,
  changeMedicalInsuranceDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/socialMedicalData'

const getSubTotalMedicalInsuranceOp126 = () => {
  changeMedicalInsuranceDataYear()
  return {
    column: medicalInsuranceData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: medicalInsuranceData.map((item) => item.key)
  }
}
import {
  homeLeaveData,
  changeHomeLeaveDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/homeLeaveData'

const getSubTotalHomeLeaveOp132 = () => {
  changeHomeLeaveDataYear()
  return {
    column: homeLeaveData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: homeLeaveData.map((item) => item.key)
  }
}
import {
  schoolFeeData,
  changeSchoolFeeDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/schoolFeeData'

const getSubTotalHousingSchoolFeeOthersOp132 = () => {
  changeSchoolFeeDataYear()
  return {
    column: schoolFeeData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: schoolFeeData.map((item) => item.key)
  }
}
import {
  relocationData,
  changeRelocationDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/relocationData'

const getSubTotalRelocationOp132 = () => {
  changeRelocationDataYear()
  return {
    column: relocationData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: relocationData.map((item) => item.key)
  }
}
import {
  othersData,
  changeOthersDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/othersData'

const getSubTotalFellingfeeOthersOp132 = () => {
  changeOthersDataYear()
  return {
    column: othersData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: othersData.map((item) => item.key)
  }
}
import {
  severanceData,
  changeSeveranceDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/severanceData'

const getSubTotalSeveranceOp141FullYear = () => {
  changeSeveranceDataYear()
  return {
    column: severanceData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: severanceData.map((item) => item.key)
  }
}
import {
  bonusData,
  changeBonusDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/bonusData'

const getSubTotalBonusOp162Op164FullYear = () => {
  changeBonusDataYear()
  return {
    column: bonusData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: bonusData.map((item) => item.key)
  }
}
import {
  CommInsurancOPData,
  changeCommInsurancOPDataYear
} from '@/views/salaryManagement/editionData/addColumnConfig/comnInsureceData'
const getCommInsurancOPData = () => {
  changeCommInsurancOPDataYear()
  return {
    column: CommInsurancOPData.map((item) => {
      return {
        ...item,
        changeYear: true,
        visible: false
      }
    }),
    names: CommInsurancOPData.map((item) => item.key)
  }
}
export {
  getEnglishName,
  getStartData,
  getHCTotal,
  getSubTotalBasePayroll,
  getSubTotal13thPayroll,
  getSubTotalHolidayPay,
  getSubTotalSalesCommOp113,
  getSubTotalSocialChargesBase,
  getSubTotalSocialChargesOp121,
  getSubTotalMedicalInsuranceOp126,
  getSubTotalHomeLeaveOp132,
  getSubTotalHousingSchoolFeeOthersOp132,
  getSubTotalRelocationOp132,
  getSubTotalFellingfeeOthersOp132,
  getSubTotalSeveranceOp141FullYear,
  getSubTotalBonusOp162Op164FullYear,
  getCommInsurancOPData
}
