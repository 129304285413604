import layout from '@/layout'

export default {
  path: '/recruitment',
  component: layout,
  redirect: '/recruitment/recruitmentManagement',
  name: 'RecruitmentManagement',
  meta: {
    title: 'RecruitmentManagement',
    icon: 'zhaopin'
  },
  children: [
    {
      path: '/recruitment/recruitmentManagement',
      name: 'recruitmentManagement',
      component: () => import('@/views/inductionManage/index.vue'),
      meta: {
        title: 'recruitmentManagement',
        icon: '#'
      }
    },
    {
      path: '/recruitment/candidateManagement',
      name: 'candidateManagement',
      component: () => import('@/views/candidateManage/index.vue'),
      meta: {
        title: 'candidateManagement',
        icon: '#'
      }
    },
    {
      path: '/recruitment/backMsg',
      name: 'backMsg',
      component: () => import('@/views/candidateManage/backMsg.vue'),
      meta: {
        title: 'backMsg',
        icon: '#'
      }
    },
    {
      path: '/recruitment/turnDownOfferRecord',
      name: 'backMsg',
      component: () => import('@/views/turnDownOfferRecord/turnDownOfferRecord.vue'),
      meta: {
        title: 'TurnDownOfferRecord',
        icon: '#'
      }
    }
  ]
}
