<template>
  <poc-container>
    <template slot="logo">
      <img :src="logoImg" />
      <div class="product-name">Iris</div>
    </template>
    <template slot="rightGroup">
      <navbar />
    </template>
    <Sidebar slot="menu" />
    <template>
      <AppMain />
    </template>
  </poc-container>
</template>

<script>
import AppMain from '@/layout/appMain.vue'
import Sidebar from './components/Sidebar/index.vue'
// import TabView from '@/components/TabView/TabView'
import navbar from '@/layout/components/Navbar'
import logoImg from '@/assets/img/off_logo.png'
export default {
  name: 'Layout',
  components: { AppMain, Sidebar, navbar },

  data() {
    return {
      logoImg
    }
  }
}
</script>

<style lang="scss" scoped>
.poc-container {
  .edgelogo {
    width: 70px;
    height: 30px !important;
    margin-left: 31px;
  }
  .product-name {
    font-family: 'TradeGothicLH-Extended' !important;
    font-weight: bold;
    font-size: 20px;
  }
}
</style>
