export const employees = [
  {
    path: '/employees/employeesItemEdit',
    name: 'employeesItemEdit',
    component: () => import('@/views/employee/pages/employeesItemEdit.vue'),
    meta: {
      title: '员工编辑',
      hidden: true,
      activeMenu: '/employees/hired'
    }
  },
  // {
  //   path: '/employees/employeesItemDetails',
  //   name: 'employeesItemDetails',
  //   component: () =>
  //     import('@/views/employee/components/detailParentManage.vue'),
  //   meta: {
  //     title: '员工详情',
  //     hidden: false,
  //     // activeMenu: '/employees/hired'
  //   }
  // }
]
