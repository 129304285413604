import ElementUI from 'element-ui'
import Vue from 'vue'
import i18n from 'publicis-common/packages/i18n'
// import 'element-ui/lib/theme-chalk/index.css'
// import { message } from '@/utils/resetMessage'

export default function (Vue) {
  // Vue.prototype.$message = message //重写message提示框  一定要放在Vue.use(ElementUI)之后
  Vue.use(ElementUI, {
    size: 'mini',
    zIndex: 4000
    // i18n: (key, value) => i18n.t(key, value)
  })
}
