import $http, { $httpOrigin } from '@/utils/request'

export default {
  // 新增角色
  addRole(params) {
    return $http.post('/sysRole/save', params)
  },
  exportDataList2({ roleName, pageNum, pageSize, ids }) {
    window.location = `${process.env['VUE_APP_BASE_API']}/api/iris/sysRole/export?roleName=${roleName}&pageNum=${pageNum}&pageSize=${pageSize}&ids=${ids}&token=${localStorage.iriseToken}`
  },
  // 修改用户信息
  editUser({
    beginTime,
    email,
    endTime,
    mobile,
    userCode,
    userName,
    status,
    remark,
    id
  }) {
    return $http.post('/sysRole/update', {
      beginTime,
      email,
      endTime,
      mobile,
      userCode,
      userName,
      status,
      remark,
      id
    })
  },
  // 启用停用
  changeRowStatus({ id, status }) {
    return $http.post('/sysRole/changeStatus', { id, status })
  },

  // 获取基础数据列表
  getDataList({ roleName, pageNum, pageSize }) {
    return $http.get('/sysRole/list', {
      params: {
        roleName,
        pageNum,
        pageSize
      }
    })
  },
  // 获取弹出层权限待分配列表
  getOrgUnAssignList(roleId) {
    return $http.get('/sysRole/getUnAssignList', {
      params: {
        roleId
      }
    })
  },
  // 获取弹出层权限已分配列表
  getOrgAssignList(roleId) {
    return $http.get('/sysRole/getAssignList', {
      params: {
        roleId
      }
    })
  },
  // 获取弹出层组织====待分配列表
  getRoleOrgUnAssignList(roleId) {
    return $http.get('/sysRole/getGroupUnAssignList', {
      params: {
        roleId
      }
    })
  },
  // 获取弹出层组织====已分配列表
  getRoleOrgAssignList(roleId) {
    return $http.get('/sysRole/getGroupAssignList', {
      params: {
        roleId
      }
    })
  },
  // 获取单个用户信息
  getSingleMsg({ id }) {
    return $http.get('/sysRole/get', {
      params: {
        id
      }
    })
  },

  // 获取已分配机构
  getOrgList({ roleId, pageNum, pageSize }) {
    return $http.get('/sysUser/getOrgList', {
      params: {
        roleId,
        pageNum,
        pageSize
      }
    })
  },
  // 获取已分配角色
  getRoleList({ pageNum, pageSize }) {
    return $http.get('/sysUser/getRoleList', {
      params: {
        pageNum,
        pageSize
      }
    })
  },
  // 获取已分配权限
  getPermissionList({ roleId, pageNum, pageSize }) {
    return $http.get('/sysRole/getPermissionAssignList', {
      params: {
        roleId,
        pageNum,
        pageSize
      }
    })
  },
  getRoleOrgList({ roleId, pageNum, pageSize }) {
    return $http.get('/sysRole/getOrgAssignList', {
      params: {
        roleId,
        pageNum,
        pageSize
      }
    })
  },
  // 获取已分配权限
  getTreeSysOrg({ orgName, status }) {
    return $http.get('/sysOrg/getTree', {
      params: {
        orgName,
        status
      }
    })
  },
  // 删除排期
  deleteRow(ids) {
    return $http.post('/sysRole/delete', ids)
  }
}
