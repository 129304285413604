export default {
  namespaced: true,
  state: () => ({
    active: 0,
    chargeOp131FormData: {
      period: ''
    },
    type: ''
  }),
  mutations: {
    CHANGE_active(state, obj) {
      state.active = obj
    },
    CHANGE_SelectVersionFormData(state, obj) {
      state.chargeOp131FormData = obj
    },
    CHANGE_Type(state, obj) {
      state.type = obj
    }
  },
  actions: {
    changeVersionActive(context, payload) {
      context.commit('CHANGE_active', payload)
    },
    changeChargeOp131FormData(context, payload) {
      context.commit('CHANGE_SelectVersionFormData', payload)
    },
    changeType(context, payload) {
      context.commit('CHANGE_Type', payload)
    }
  }
}
