import { publicRoutes } from '@/router/publicRoutes'
import store from '@/store'
const { VueRouter } = $PCommon
import Vue from 'vue'
Vue.use(VueRouter)

const createRouter = () =>
  new VueRouter({
    mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
    routes: publicRoutes
  })
const router = createRouter()
// 在路由守卫中添加全局拦截
router.beforeEach((to, from, next) => {
  // 在这里编写拦截逻
  if (to.path.includes('/dashboard')) {
    // 可以跳转到 dashboard 相关页面
    window.location.href = to.path;
  } else {
    next(); // 继续路由跳转
  }
});
export function resetRouter() {
  // const newRouter = createRouter()
  // router.matcher = newRouter.matcher // reset router
  location.reload()
}
export default router
