import { getItem } from '@/utils/storage'
import { thirteenSalaryData } from '@/views/salaryManagement/editionData/addColumnConfig/thirteenSalaryData'

export const holidayPayData = [
  {
    key: 'holidayPayOp112Jan',
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'holidayPayOp112Feb',
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'holidayPayOp112Mar',
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'holidayPayOp112Apr',
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'holidayPayOp112May',
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'holidayPayOp112Jun',
    name: 'Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'holidayPayOp112Jul',
    name: 'Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'holidayPayOp112Aug',
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'holidayPayOp112Sep',
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'holidayPayOp112Oct',
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'holidayPayOp112Nov',
    name: 'Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'holidayPayOp112Dec',
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  }
]
export const changeHolidayPayDataYear = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    holidayPayData.forEach((item) => {
      let name = item.name.split('-')[0]
      item.name = name + '-' + year
    })
  }
}
holidayPayData.map((v) => {
  v.name = 'Holiday Pay ' + v.name
})
