import { getItem } from '@/utils/storage'
import { bonusData } from '@/views/salaryManagement/editionData/addColumnConfig/bonusData'

export const CommInsurancOPData = [
  {
    key: 'commInsuranceIitOp126Jan',
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commInsuranceIitOp126Feb',
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commInsuranceIitOp126Mar',
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commInsuranceIitOp126Apr',
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commInsuranceIitOp126May',
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commInsuranceIitOp126Jun',
    name: 'Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commInsuranceIitOp126Jul',
    name: 'Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commInsuranceIitOp126Aug',
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commInsuranceIitOp126Sep',
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commInsuranceIitOp126Oct',
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commInsuranceIitOp126Nov',
    name: 'Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commInsuranceIitOp126Dec',
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 210 // 默认宽度，像素
  }
]
export const changeCommInsurancOPDataYear = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    CommInsurancOPData.forEach((item) => {
      let name = item.name.split('-')[0]
      item.name = name + '-' + year
    })
  }
}
CommInsurancOPData.map((v) => {
  v.name = 'commInsuranceIITOp126 ' + v.name
})
