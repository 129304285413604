<template>
  <!-- 支持渲染多级 menu 菜单 -->
  <el-submenu v-if="route.children.length > 0" :index="route.path" :class="{'sidebarOpened':!$store.getters.sidebarOpened}">
    <template #title>
      <menu-item :title="route.meta.title" :icon="route.meta.icon"></menu-item>
    </template>
    <!-- 循环渲染 -->
    <sidebar-item
      v-for="item in route.children"
      :key="item.path"
      :route="item"
    ></sidebar-item>
  </el-submenu>
  <!-- 渲染 item 项 -->
  <el-menu-item v-else :index="route.path" :class="{'sidebarOpenedItem':!$store.getters.sidebarOpened}">
    <menu-item
      :title="route.meta.title"
      :icon="route.meta.icon"
      @click.native="clickMenu(route)"
    ></menu-item>
  </el-menu-item>
</template>

<script>
import MenuItem from './MenuItem'
import { setItem } from '@/utils/storage'
import { POINTLIST } from '@/constant'

export default {
  name: 'SidebarItem',
  props: {
    route: {
      type: Object,
      required: true
    }
  },
  components: {
    MenuItem
  },
  methods: {
    clickMenu(val) {
      if (val) {
        this.$store.dispatch('permission/savePont', val.meta.pointList)
        setItem(POINTLIST, val.meta.pointList)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
