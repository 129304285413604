import Vue from 'vue'
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate'
import { required, integer, email } from 'vee-validate/dist/rules'

import { configure } from 'vee-validate'

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
})

// Add a rule.
extend('required', {
  ...required,
  message: 'This field is required'
})
extend('integer', {
  ...integer,
  message: 'Only numbers can be entered'
})
extend('email', {
  ...email,
  message: 'Email format error'
})

// Register it globally
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
