<template>
  <div class="navbar">
    <div class="rightButton">
      <svg-icon
        :icon="language"
        class-name="circleButton"
        @click.native="onlanguagechange"
      />
      <el-dropdown>
        <span class="el-dropdown-link">
          <svg-icon icon="PersonalCenter" class-name="circleButton" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="changePass"
            >修改密码</el-dropdown-item
          >
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      modal-append-to-body
      modal
      :close-on-click-modal="false"
      :before-close="close"
    >
      <el-form
        :model="formInline"
        :rules="rules"
        ref="formRef"
        class="demo-form-inline"
      >
        <el-form-item label="用户名">
          {{ formInline.user }}
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="formInline.password"
            type="password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="rePassword">
          <el-input
            v-model="formInline.rePassword"
            type="password"
            placeholder="请输入确认密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changePassword">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getItem } from '@/utils/storage'
const { Vuex } = $PCommon
const { mapGetters } = Vuex
const { Message } = $PCommon
export default {
  name: 'navbar',
  data() {
    return {
      dialogVisible: false,
      formInline: {
        user: '',
        password: '',
        rePassword: ''
      },
      rules: {
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ],
        rePassword: [
          {
            required: true,
            message: '请输入确认密码',
            trigger: 'blur'
          },
          {
            validator: this.validatorRepass,
            trigger: 'blur'
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters(['language'])
  },
  methods: {
    validatorRepass(rule, value, callback) {
      if (value !== this.formInline.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },
    close() {
      this.dialogVisible = false
    },
    changePass() {
      this.formInline.user = getItem('userName') || ''
      this.dialogVisible = true
    },
    logout() {
      this.$store.dispatch('user/logout')
    },
    changePassword() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          // const Salt = '123ABCdef*'
          // let pass = md5(this.formInline.password + Salt)
          this.$Api.sys
            .UpdatePassword({
              password: this.formInline.password
            })
            .then((res) => {
              if (res.code === 200) {
                Message.success('密码修改成功,请重新登录')
                this.logout()
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    goHome() {
      this.$router.push({
        path: '/'
      })
    },
    onlanguagechange() {
      if (this.language === 'zh') {
        this.$store.commit('app/setLanguage', 'en')
        this.$i18n.locale = this.language
      } else {
        this.$store.commit('app/setLanguage', 'zh')
        this.$i18n.locale = this.language
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rightButton {
  margin-right: 20px;
  .circleButton {
    width: 28px;
    height: 28px;
    margin-left: 8px;
    position: relative;
    cursor: pointer;
  }
}
</style>
