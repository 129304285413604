import { getItem } from '@/utils/storage'
import { salesCommData } from '@/views/salaryManagement/editionData/addColumnConfig/salesCommData'

export const socialChargesData = [
  {
    key: 'socialChargesBaseJan',
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'socialChargesBaseFeb',
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'socialChargesBaseMar',
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'socialChargesBaseApr',
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'socialChargesBaseMay',
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'socialChargesBaseJun',
    name: 'Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'socialChargesBaseJul',
    name: 'Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'socialChargesBaseAug',
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'socialChargesBaseSep',
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'socialChargesBaseOct',
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'socialChargesBaseNov',
    name: 'Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 120 // 默认宽度，像素
  },
  {
    key: 'socialChargesBaseDec',
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 120 // 默认宽度，像素
  }
]
export const changeSocialChargesDataYear = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    socialChargesData.forEach((item) => {
      let name = item.name.split('-')[0]
      item.name = name + '-' + year
    })
  }
}
socialChargesData.map((v) => {
  v.name = 'Social Charge ' + v.name
})
