import store from '@/store'
import mZhLocale from './lang/zh'
import mEnLocale from './lang/en'
const { VueI18n } = $PCommon
const messages = {
  en: {
    ...mEnLocale
  },
  zh: {
    ...mZhLocale
  }
}

/**
 * 返回当前 lang
 */
function getLanguage() {
  return store && store.getters && store.getters.language
}

// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: getLanguage(), // 设置地区
  globalInjection: true,
  messages // 设置地区信息
})
export default i18n
