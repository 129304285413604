export const RoleManage = [
  {
    path: '/system/permissionManage/details',
    name: 'MenuRoleDetails',
    component: () =>
      import('@/views/menuRoleManage/components/MenuRoleDetails.vue'),
    meta: {
      title: '权限详情',
      hidden: true,
      activeMenu: '/system/permissionManage'
    }
  },
  {
    path: '/system/permissionManage/edit',
    name: 'MenuRoleEdit',
    component: () =>
      import('@/views/menuRoleManage/components/MenuRoleEdit.vue'),
    meta: {
      title: '权限编辑',
      hidden: true,
      activeMenu: '/system/permissionManage'
    }
  },
  {
    path: '/OrganizationInformationForm',
    name: 'OrganizationInformationForm',
    component: () =>
      import(
        '@/components/DialogForm/components/OrganizationInformationForm.vue'
      ),
    meta: {
      title: 'OrganizationInformationForm'
    }
  }
]
