import request from '@/utils/request'

/**
 * 登录
 */
export const login = (data) => {
  return request({
    url: '/login/userLogin',
    method: 'POST',
    data
  })
}
export const getPhoto = () => {
  return request({
    url: '/login/verificationCode',
    method: 'get'
  })
}

export const getVueBaseApi = () => {
  return process.env['VUE_APP_BASE_API']
}
export const getSSOApi = () => {
  return process.env['VUE_APP_BASE_SSO_API']
}
export const getAppName = () => {
  return process.env['VUE_APP_BASE_APP_NAME']
}

export const lionLogin = (data) => {
  return request({
    url: '/login/lionLogin',
    method: 'POST',
    data
  })
}
export const UpdatePassword = (data) => {
  return request({
    url: '/sysUser/updatePassword',
    method: 'POST',
    data
  })
}

export default {
  UpdatePassword
}
