export const addNameData = [
  {
    key: 'hfmCode',
    name: 'HFM Code',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'level2',
    name: 'Level 2',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'level3',
    name: 'Level 3',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'bu',
    name: 'BU',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 180 // 默认宽度，像素
  },
  {
    key: 'location',
    name: 'Location',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 120 // 默认宽度，像素
  },
  {
    key: 'function',
    name: 'Function',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'team',
    name: 'Team',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'staffNo',
    name: 'Staff No',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 120 // 默认宽度，像素
  },
  {
    key: 'position',
    name: 'Position',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'grading',
    name: 'Grading',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'client',
    name: 'Client',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'localExpat',
    name: 'Local/Expat',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'chineseName',
    name: 'Chinese Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  }
]
