export const fundsManage = [
  {
    path: '/org/socialManage/fundsAdd',
    name: 'fundsAdd',
    component: () => import('@/views/fundsManage/fundsAdd'),
    meta: {
      title: '社保公积金',
      hidden: true
    }
  }
]
