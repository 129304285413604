import { RoleManage } from '@/router/publicModule/RoleManage'
import { RecruitmentQuota } from '@/router/publicModule/RecruitmentQuota'
import { intern } from '@/router/publicModule/intern'
import { pcTracker } from '@/router/publicModule/pcTracker'
import { fundsManage } from '@/router/publicModule/fundsManage'
import { employees } from '@/router/publicModule/employees'
import { financialQuota } from '@/router/publicModule/financialQuota'
import { salaryManagementPub } from '@/router/publicModule/salaryManagementPub'

export const publicHideRoute = [
  ...RoleManage,
  ...RecruitmentQuota,
  ...financialQuota,
  ...intern,
  ...pcTracker,
  ...fundsManage,
  ...employees,
  ...salaryManagementPub
]
