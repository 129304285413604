export const RecruitmentQuota = [
  {
    path: '/employmentApplication/RecruitmentQuota',
    name: 'MenuRoleDetails',
    component: () =>
      import('@/views/quotaManagement/components/RecruitmentQuota.vue'),
    meta: {
      title: '招聘配额',
      hidden: true,
      activeMenu: '/employmentApplication/quotaManagement'
    }
  },
  {
    path: '/employmentApplication/hcIsBroughtOut',
    name: 'hcIsBroughtOut',
    component: () =>
      import('@/views/quotaManagement/components/hcIsBroughtOut.vue'),
    meta: {
      title: 'hcIsBroughtOut',
      hidden: true,
      activeMenu: '/employmentApplication/quotaManagement'
    }
  },
  {
    path: '/employmentApplication/ResignationRelatedInformation',
    name: 'ResignationRelatedInformation',
    component: () =>
      import(
        '@/views/quotaManagement/components/ResignationRelatedInformation.vue'
      ),
    meta: {
      title: 'hcIsBroughtOut',
      hidden: true,
      activeMenu: '/employmentApplication/quotaManagement'
    }
  }
]
