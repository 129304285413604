import router from '@/router'
import store from '@/store'
// 白名单。没有token可以进入
const whiteList = ['/login', '/SSOPage']
/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  if (store.getters.token) {
    // 如果有token 不允许重复进入登录界面
    if (whiteList.includes(to.path)) {
      // 默认跳转到首页
      next('/')
    } else {
      // 判断用户资料是否获取
      if (!store.getters.hasUserInfo) {
        /*
          登录成功后将菜单权限保存到local中，如果vuex中的信息没有了，直接从local取,模拟调取用户信息接口
         */
        const menuList = await store.dispatch('user/getUserInfo')
        if (menuList == 'undefined' || menuList == undefined) {
          next('/login')
        }
        // 筛选出符合权限的菜单
        const filterRoutes = await store.dispatch(
          'permission/filterRoutes',
          menuList
        )
        // 利用 addRoute 循环添加
        filterRoutes.forEach((item) => {
          router.addRoute(item)
        })
        return next(to.fullPath)
      }
      next()
    }
  } else {
    // 没有token的情况下，可以进入白名单
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  }
})
