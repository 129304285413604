import user from '@/store/modules/user'
import $http, { $httpOrigin } from '@/utils/request'

export default {

 
  exportDataListAll({ attendanceDate,formalDateStart,formalDateEnd,formalStatus,hfmCode,nameEn,nation,orgId,payBatch,status }) {
    window.location = `/staffInfo/export?attendanceDate=${attendanceDate}&formalDateStart=${formalDateStart}&formalDateEnd=${formalDateEnd}&formalStatus=${formalStatus}
    &hfmCode=${hfmCode}&nameEn=${nameEn}&nation=${nation}&orgId=${orgId}&payBatch=${payBatch}&status=${status}&token=${localStorage.iriseToken}`
  },
  exportDataList({ attendanceDate,formalDateStart,formalDateEnd,formalStatus,hfmCode,nameEn,nation,orgId,payBatch,status }) {
    window.location = `/staffInfo/exportPayAll?attendanceDate=${attendanceDate}&formalDateStart=${formalDateStart}&formalDateEnd=${formalDateEnd}&formalStatus=${formalStatus}
    &hfmCode=${hfmCode}&nameEn=${nameEn}&nation=${nation}&orgId=${orgId}&payBatch=${payBatch}&status=${status}&token=${localStorage.iriseToken}`
  },

  
  //确认付款按钮
  getsurePay(data) {
    return $http.get('/internAttendanceSummaryPay/confirmPay', {
      params: {
        ...data
      } 
    })
  },    

  
  
  
   // 获取所有流水号
   getpayBatch({ }) {
    return $http.get('/payExport/payBatch', {
      params: {
        
      } 
    })
  },
  // 获取考勤汇总列表
  getDataList(data) {
    return $http.get('/internAttendanceSummaryPay/attendanceSum', {
      params: {
        ...data
      } 
    })
  },

}
