<template>
  <div class="menuIcon">
    <!--    <PocIcon-->
    <!--      :name="icon"-->
    <!--      class="poc-icon-primary poc-size14"-->
    <!--      style="margin-right: 4px"-->
    <!--    />-->
    <svg-icon class="svgIconContent" :icon="icon"></svg-icon>
    <span>{{ $t(`route.${title}`) }}</span>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.menuIcon {
  display: flex;
  align-items: center;
  .svgIconContent {
    margin-right: 8px;
    font-size: 20px;
    color: $--color-button-primary;
  }
}
</style>
