import getters from './getters'
import permission from '@/store/modules/permission'
import user from '@/store/modules/user'
import app from '@/store/modules/app'
import employee from '@/store/modules/employee'
import itData from '@/store/modules/itData'
import ChargeOut from '@/store/modules/ChargeOut'
import SelectVersion from '@/store/modules/SelectVersion'
import chargeOp131 from '@/store/modules/ChargeOp131'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  modules: {
    user,
    app,
    permission,
    employee,
    ChargeOut,
    SelectVersion,
    chargeOp131,
    itData
  }
})
