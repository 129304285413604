import layout from '@/layout'

export default {
  path: '/employmentApplication',
  component: layout,
  redirect: '/employmentApplication/personnelRequisition',
  name: 'employmentApplication',
  meta: {
    title: 'employmentApplication',
    icon: 'people'
  },
  children: [
    {
      path: '/employmentApplication/personnelRequisition',
      name: 'personnelRequisition',
      component: () =>
        import('@/views/personnelRequisition/personnelRequisition.vue'),
      meta: {
        title: 'personnelRequisition',
        icon: '#'
      }
    },
    {
      path: '/employmentApplication/quotaManagement',
      name: 'quotaManagement',
      component: () => import('@/views/quotaManagement/quotaManagement.vue'),
      meta: {
        title: 'quotaManagement',
        icon: '#'
      }
    },
    {
      path: '/employmentApplication/financialApproval',
      name: 'financialApproval',
      component: () =>
        import('@/views/financialApproval/financialApproval.vue'),
      meta: {
        title: 'financialApproval',
        icon: '#'
      }
    },
    {
      path: '/employmentApplication/ctoApproval',
      name: 'ctoApproval',
      component: () =>
        import('@/views/ctoApproval/ctoApproval.vue'),
      meta: {
        title: 'ctoApproval',
        icon: '#'
      }
    }
    // {
    //   path: '/employmentApplication/recruitmentManagement',
    //   name: 'recruitmentManagement',
    //   component: () => import('@/views/inductionManage/index.vue'),
    //   meta: {
    //     title: 'recruitmentManagement',
    //     icon: '#'
    //   }
    // },
    // {
    //   path: '/employmentApplication/candidateManagement',
    //   name: 'candidateManagement',
    //   component: () => import('@/views/candidateManage/index.vue'),
    //   meta: {
    //     title: 'candidateManagement',
    //     icon: '#'
    //   }
    // },
    // {
    //   path: '/employmentApplication/backMsg',
    //   name: 'backMsg',
    //   component: () => import('@/views/candidateManage/backMsg.vue'),
    //   meta: {
    //     title: 'backMsg',
    //     icon: '#'
    //   }
    // }
  ]
}
