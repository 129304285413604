import Layout from '@/layout'
import { publicHideRoute } from '@/router/publicModule'

const { CommonRouter } = $PCommon
const removeArr = ['/404', '*']
const catch404 = CommonRouter.filter((item) => !removeArr.includes(item.path))
import { candidateManage } from '@/router/publicModule/candidateManage'

export const publicRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/Login.vue')
  },
  {
    path: '/SSOPage',
    name: 'SSOPage',
    component: () => import('@/views/SSOPage/SSOPage.vue')
  },
  // 背调报告
  ...candidateManage,

  {
    path: '/employees/employeesItemDetails',
    name: 'employeesItemDetails',
    component: () =>
      import('@/views/employee/components/detailParentManage.vue'),
    meta: {
      title: '员工详情',
      // hidden: false,
      // activeMenu: '/employees/hired'
    }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home/index'),
        meta: {
          title: 'home',
          icon: 'home'
        }
      },
      //公共路由写在模块里面，别在这里写！！！
      ...publicHideRoute
    ]
  },
  ...catch404
]
