import { getEnglishName } from '@/views/salaryManagement/components/modal/ChargeOutDialog/mixin/FormateHiddenTableColumn'

import { cutExpend } from '@/views/salaryManagement/components/modal/ChargeOutDialog/chargeOutColumn'

export const AdjustmentColumn = [
  // {
  //   key: 'chargeType',
  //   name: 'Cost / HC',
  //   dataType: 'string',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'left',
  //   fold: false,
  //   width: 150 // 默认宽度，像素
  // },
  // {
  //   key: 'total',
  //   name: 'Total',
  //   dataType: 'string',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'left',
  //   fold: false,
  //   width: 150 // 默认宽度，像素
  // },
  {
    key: 'jan',
    name: 'Jan',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  },
  {
    key: 'feb',
    name: 'Feb',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  },
  {
    key: 'mar',
    name: 'Mar',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  },
  {
    key: 'apr',
    name: 'Apr',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  },
  {
    key: 'may',
    name: 'May',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  },
  {
    key: 'jun',
    name: 'Jun',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  },
  {
    key: 'jul',
    name: 'Jul',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  },
  {
    key: 'aug',
    name: 'Aug',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  },
  {
    key: 'sep',
    name: 'Sep',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  },
  {
    key: 'oct',
    name: 'Oct',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  },
  {
    key: 'dec',
    name: 'Dec',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  },
  {
    key: 'nov',
    name: 'Nov',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: false,
    width: 150 // 默认宽度，像素
  }
]
export const AdjustCutColumn = [
  {
    key: 'englishName',
    name: 'English Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: true,
    collapsable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getEnglishName().names)
    },
    width: 140 // 默认宽度，像素
  },
  //导入English Name折叠内容
  ...getEnglishName().column
]
