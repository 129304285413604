import { getItem } from '@/utils/storage'
import { homeLeaveData } from '@/views/salaryManagement/editionData/addColumnConfig/homeLeaveData'

export const schoolFeeData = [
  {
    key: 'housingSchoolFeeOthersOp132Jan',
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  },
  {
    key: 'housingSchoolFeeOthersOp132Feb',
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  },
  {
    key: 'housingSchoolFeeOthersOp132Mar',
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  },
  {
    key: 'housingSchoolFeeOthersOp132Apr',
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  },
  {
    key: 'housingSchoolFeeOthersOp132May',
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  },
  {
    key: 'housingSchoolFeeOthersOp132Jun',
    name: 'Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  },
  {
    key: 'housingSchoolFeeOthersOp132Jul',
    name: 'Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  },
  {
    key: 'housingSchoolFeeOthersOp132Aug',
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  },
  {
    key: 'housingSchoolFeeOthersOp132Sep',
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  },
  {
    key: 'housingSchoolFeeOthersOp132Oct',
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  },
  {
    key: 'housingSchoolFeeOthersOp132Nov',
    name: 'Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  },
  {
    key: 'housingSchoolFeeOthersOp132Dec',
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 220 // 默认宽度，像素
  }
]
export const changeSchoolFeeDataYear = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    schoolFeeData.forEach((item) => {
      let name = item.name.split('-')[0]
      item.name = name + '-' + year
    })
  }
}
schoolFeeData.map((v) => {
  v.name = 'Housing School FeeOthers' + v.name
})
