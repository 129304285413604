import store from '@/store'

export const exportDownload = (baseUrlTarget, data) => {
  const url = process.env['VUE_APP_BASE_API']
  let baseUrl = `${url}/api/iris/${baseUrlTarget}?`
  Object.keys(data).forEach((item) => {
    baseUrl += `${item}=${data[item]}&`
  })
  baseUrl += `token=${store.getters.token}`
  // window.location = baseUrl
  window.open(baseUrl, '_blank');
}
