const getters = {
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  /**
   * @returns true 表示已存在用户信息
   */
  hasUserInfo: (state) => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  sidebarOpened: (state) => state.app.sidebarOpened,
  language: (state) => state.app.language,
  indexYear: (state) => state.app.indexYear,
  mainColor: (state) => state.theme.mainColor,
  point: (state) => state.permission.point,
  userRole: (state) => state.user.userRole,
  staffInfoCount: (state) => state.employee.staffInfoCount,
  itData: (state) => state.itData
}
export default getters
