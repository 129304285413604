import { AdjustCutColumn } from '@/views/salaryManagement/components/modal/ChargeOutDialog/AdjustmentColumn'
import { gethfmCodes, getLevel2Orgs } from '@/api/pctrakerop131'
import { getAssigmentProjectLevel2 } from '@/api/personnelRequisition'
import { check, typeRange } from '@/utils/util'
const titleCase = (str) => {
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
}
export const demoData = {
  hcInformationId: '',
  chargeToBp: '',
  chargeToBpInputData: '',
  selectHrbpType: 'selectHrbp',
  chargeToBpData: {
    assigmentProjectLevel2: '',
    hfmCode: '',
    hrbpLevel3: ''
  },
  assigmentProjectLevel2List: [],
  hfmCodeList: [],
  hrbpLevel3List: [],
  description: '',
  descriptionData: '',
  englishName: '',
  hfmCode: '',
  functionSelect: '',
  functionSelectData: 'inputNumber',
  functionSelectList: [
    {
      label: '填数值',
      value: 'inputNumber'
    },
    {
      label: '填比例',
      value: 'inputProportion'
    }
  ],
  chargeType: 'Cost',

  jan: 0,
  feb: 0,
  mar: 0,
  apr: 0,
  may: 0,
  jun: 0,
  jul: 0,
  aug: 0,
  sep: 0,
  oct: 0,
  dec: 0,
  nov: 0,
  operate: ''
}
let sumNames = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'dec',
  'nov'
]
export default {
  namespaced: true,
  state: () => ({
    active: 0,
    buttonActive: '',
    editionId: '',
    selectVal: [],
    tableData: [],
    type: ''
  }),
  mutations: {
    CHANGE_ACTIVE(state, obj) {
      state.active = obj
    },
    CHANGE_buttonActive(state, obj) {
      state.buttonActive = obj
    },
    CHANGE_editionId(state, obj) {
      state.editionId = obj
    },
    CHANGE_selectVal(state, obj) {
      state.selectVal = obj
    },
    CHANGE_tableData(state, obj) {
      state.tableData = obj
    },
    DELETE_selectVal(state, obj) {
      let findIndex = state.selectVal.findIndex((item) => item.id === obj)
      if (findIndex) {
        state.selectVal.splice(findIndex, 1)
      }
      let deleteId = obj
      let arr = []
      state.tableData.forEach((item, index) => {
        if (item.hcInformationId === deleteId) {
          arr.push(index)
        }
      })
      arr.forEach((index) => {
        delete state.tableData[index]
      })
    },
    CHANGE_Type(state, obj) {
      state.type = obj
    }
  },
  actions: {
    changeActive(context, payload) {
      context.commit('CHANGE_ACTIVE', payload)
    },
    changeButtonActive(context, payload) {
      context.commit('CHANGE_buttonActive', payload)
    },
    changeEditionId(context, payload) {
      context.commit('CHANGE_editionId', payload)
    },
    changeSelectVal(context, payload) {
      context.commit('CHANGE_selectVal', payload)
    },
    changeTableData(context, payload) {
      context.commit('CHANGE_tableData', payload)
    },
    deleteSelectVal(context, payload) {
      context.commit('DELETE_selectVal', payload)
    },
    formatTableData(context, payload) {
      return new Promise((resolve, reject) => {
        let formatTableArr = []
        if (payload && check(payload) === typeRange.array && payload.length) {
          let CostData = JSON.parse(JSON.stringify(demoData))
          gethfmCodes().then((res) => {
            CostData.hfmCodeList = res.data
            getAssigmentProjectLevel2().then((res1) => {
              CostData.assigmentProjectLevel2List = res1.data
              payload.forEach((selectItem) => {
                let id = selectItem.id || selectItem.hcInformationId
                CostData.hcInformationId = id
                CostData.chargeToBp = `chargeToBp${id}`
                CostData.description = `description${id}`
                CostData.englishName = selectItem.englishName
                CostData.isHc = true
                //折叠的数据
                AdjustCutColumn.forEach((item) => {
                  if (selectItem[item.key]) {
                    CostData[item.key] = selectItem[item.key]
                  } else {
                    CostData[item.key] = '-'
                  }
                })
                CostData.functionSelect = `functionSelect${id}`
                CostData.operate = `operate${id}`
                CostData.popoverDisplay = false

                // sumNames.forEach((itemName) => {
                //   selectItem[`hc${itemName}`] = selectItem[itemName]
                //   delete selectItem[itemName]
                // })
                CostData = { ...CostData, ...selectItem }
                let a = JSON.parse(JSON.stringify(CostData))
                let b = JSON.parse(JSON.stringify(CostData))
                a.chargeType = 'Cost'
                formatTableArr.push(a)
                b.chargeType = 'HC'
                formatTableArr.push(b)
              })

              resolve(formatTableArr)
            })
          })

        } else {
          resolve([])
        }
      })
    },
    formatCellDisplay(context, payload) {
      return new Promise((resolve, reject) => {
        let formatTableArr = []
        payload.forEach((selectItem) => {
          let CostData = JSON.parse(JSON.stringify(demoData))
          let id = selectItem.id || selectItem.hcInformationId
          CostData.hcInformationId = id
          CostData.chargeToBp = `chargeToBp${id}`
          CostData.description = `description${id}`
          CostData.isHc = true
          AdjustCutColumn.forEach((item) => {
            if (selectItem[item.key]) {
              CostData[item.key] = selectItem[item.key]
            } else {
              CostData[item.key] = '-'
            }
          })
          CostData.functionSelect = `functionSelect${id}`
          CostData.operate = `operate${id}`
          CostData.popoverDisplay = false
          // sumNames.forEach((itemName) => {
          //   selectItem[`hc${titleCase(itemName)}`] = selectItem[itemName]
          //   CostData[itemName] = selectItem[itemName]
          //   delete selectItem[itemName]
          // })
          gethfmCodes().then((res) => {
            CostData.hfmCodeList = res.data
            getLevel2Orgs().then((res1) => {
              res1.data = res1.data.map(a=>{
                a.label = a.orgName
                a.value = a.id
                return a
              })
              CostData.assigmentProjectLevel2List = res1.data
              CostData = { ...CostData, ...selectItem }
              let a = JSON.parse(JSON.stringify(CostData))
              formatTableArr.push(a)
              resolve(formatTableArr)
            })
          })
        })
      })
    },
    changeChargeType(context, payload) {
      context.commit('CHANGE_Type', payload)
    }
  }
}
