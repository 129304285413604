export const addDateData = [
  {
    key: 'leavingDate',
    name: 'Leaving Date',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 130 // 默认宽度，像素
  },
  {
    key: 'offerDate',
    name: 'Offer Date',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 130 // 默认宽度，像素
  },
  {
    key: 'exJobHolderName',
    name: 'Ex-job holder Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  },
  {
    key: 'exJobHolderLeavingDate',
    name: 'Leaving Date (ex-job holder)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 200 // 默认宽度，像素
  },
  {
    key: 'salaryOfMonthPredict',
    name: 'Salary of Month（预估）',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 180 // 默认宽度，像素
  },
  {
    key: 'salaryOfMonthActual',
    name: 'Salary of Month (实际)',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 180 // 默认宽度，像素
  },
  {
    key: 'allowancePerMonth',
    name: 'Allowance per month',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 160 // 默认宽度，像素
  },
  {
    key: 'lifeInsurancePerMonth',
    name: 'Life Insurance/per month',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsurancePerMonth',
    name: 'Medical  Insurance/per month',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 210 // 默认宽度，像素
  },
  {
    key: 'commercialTaxPerMonthBornedByCompany',
    name: 'commercial tax/per month-borned by company',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 320 // 默认宽度，像素
  },
  {
    key: 'thirteenSalary',
    name: '13th Payroll',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    width: 150 // 默认宽度，像素
  }
]
