import { getItem } from '@/utils/storage'
import { socialChargesOp121Data } from '@/views/salaryManagement/editionData/addColumnConfig/socialCharges121Data'

export const medicalInsuranceData = [
  {
    key: 'medicalInsuranceOp126Jan',
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsuranceOp126Feb',
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsuranceOp126Mar',
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsuranceOp126Apr',
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsuranceOp126May',
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsuranceOp126Jun',
    name: 'Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsuranceOp126Jul',
    name: 'Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsuranceOp126Aug',
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsuranceOp126Sep',
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsuranceOp126Oct',
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsuranceOp126Nov',
    name: 'Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'medicalInsuranceOp126Dec',
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  }
]
export const changeMedicalInsuranceDataYear = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    medicalInsuranceData.forEach((item) => {
      let name = item.name.split('-')[0]
      item.name = name + '-' + year
    })
  }
}
medicalInsuranceData.map((v) => {
  v.name = 'medical Insurance ' + v.name
})
