import request from '@/utils/request'
import store from '@/store'
import dayjs from 'dayjs'
import $http from "@/utils/request";
import { exportDownload } from "@/api/export";

export const hrRafFormList = (data) => {
  return request({
    url: '/hrRafForm/listNew',
    params: {
      ...data
    }
  })
}

// export const getDepartmentList = (data) => {
//   return request({
//     url: '/hrRafForm/getDepartmentList',
//     params: {
//       ...data
//     }
//   })
// }
export const getDescriptionLevel3 = (data) => {
  return request({
    url: '/hrRafForm/getDescriptionLevel3',
    params: {
      ...data
    }
  })
}
export const changeSubmissionStatus = (data) => {
  return request({
    url: '/hrRafForm/changeSubmissionStatus',
    method: 'POST',
    data
  })
}
export const changeRecallStatus = (data) => {
  return request({
    url: '/hrRafForm/recall',
    method: 'POST',
    data
  })
}

export const getCountry = () => {
  return request({
    url: '/hrRafForm/getCountry'
  })
}

export const getShareData = (data) => {
  return request({
    url: '/hcInformation/getShareData',
    method: 'POST',
    data
  })
}
export const saveShareData = (data) => {
  return request({
    url: '/hcInformation/saveShareData',
    method: 'POST',
    data
  })
}
export const getBloodHistory = (data) => {
  return request({
    url: '/hcInformation/getBloodHistory',
    params: {
      ...data
    }
  })
}

export const getLegalEntity = (data) => {
  return request({
    url: '/hrRafForm/getLegalEntity',
    params: {
      ...data
    }
  })
}
export const getLegalEntityList = (data) => {
  return request({
    url: '/hrRafForm/getLegalEntityList',
    params: {
      ...data
    }
  })
}
export const getEntityHfmCode = () => {
  return request({
    url: '/hrRafForm/getEntityHfmCode'
  })
}
export const getCodeValue = () => {
  return request({
    url: '/hrRafForm/getCodeValue'
  })
}
export const getEuroConv = () => {
  return request({
    url: '/hrRafForm/getEuroConv'
  })
}
export const getClient1 = () => {
  return request({
    url: '/hrRafForm/getClient'
  })
}
export const savePerson = (data) => {
  return request({
    url: '/hrRafForm/save',
    method: 'POST',
    data
  })
}
export const getFormData = (data) => {
  return request({
    url: '/hrRafForm/get',
    params: {
      ...data
    }
  })
}
export const getAssigmentProjectLevel2 = () => {
  return request({
    url: '/hrRafForm/getAssigmentProjectLevel2'
  })
}
export const getBuNameByHfmCode = (data) => {
  return request({
    url: '/hrRafForm/getBuNameByHfmCode',
    params: {
      ...data
    }
  })
}

export const getBusinessUnitNameByHfmCode = (data) => {
  return request({
    url: '/hrRafForm/getBusinessUnitNameByHfmCode',
    params: {
      ...data
    }
  })
}
export const getBusinessUnitList = (data) => {
  return request({
    url: '/hrRafForm/getBusinessUnitName',
    params: {
      ...data
    }
  })
}
export const getWorkingLocationByHfmCode = (data) => {
  return request({
    url: '/hrRafForm/getWorkingLocationByHfmCode',
    params: {
      ...data
    }
  })
}
export const getLevel2 = (data) => {
  return request({
    url: '/hrRafForm/getLevel2',
    params: {
      ...data
    }
  })
}
export const getDepartmentList = (data) => {
  return request({
    url: '/hrRafForm/getDepartmentList',
    params: {
      ...data
    }
  })
}

export const getLevel3 = (data) => {
  return request({
    url: '/hrRafForm/getLevel3',
    params: {
      ...data
    }
  })
}
export const deleteForm = (data) => {
  return request({
    url: '/hrRafForm/delete',
    method: 'POST',
    data
  })
}
export const commited = (data) => {
  return request({
    url: '/hrRafForm/commited',
    method: 'POST',
    data
  })
}
export const checkEdit = (data) => {
  return request({
    url: '/hrRafForm/checkEdit',
    params: {
      ...data
    }
  })
}
export const getHRBPOwnerList = (data) => {
  return request({
    url: '/hcInformation/getHRBPOwnerList',
    params: {
      ...data
    }
  })
}

export const exportPersone = (data) => {
  const url = process.env['VUE_APP_BASE_API']
  let baseUrl = `${url}/api/iris/hrRafForm/export?`
  Object.keys(data).forEach((item) => {
    baseUrl += `${item}=${data[item]}&`
  })
  baseUrl += `token=${store.getters.token}`
  window.location = baseUrl
}
export const exportQuota = (data) => {
  const url = process.env['VUE_APP_BASE_API']
  let baseUrl = `${url}/api/iris/hcInformation/export?`
  Object.keys(data).forEach((item) => {
    baseUrl += `${item}=${data[item]}&`
  })
  baseUrl += `token=${store.getters.token}`
  window.location = baseUrl
}
export const getOrganizations = (data) => {
  return request({
    url: '/social-security-base-org/getOrganizations',
    params: {
      ...data
    }
  })
}

export const getSocialSecurityBase = (data) => {
  return request({
    url: '/social-security-base/getSocialSecurityBase',
    params: {
      ...data
    }
  })
}
export const getNewHc = (data) => {
  return request({
    url: '/hcInformation/getNewHc',
    params: {
      ...data
    }
  })
}

export const getWorkLocation = (data) => {
  return request({
    url: '/hrRafForm/getWorkLocation',
    params: {
      ...data
    }
  })
}
export const getWorkLocationList = (data) => {
  return request({
    url: '/hrRafForm/getWorkLocationList',
    params: {
      ...data
    }
  })
}
export const getHcInformationByHcId = (data) => {
  return request({
    url: '/hcInformation/getHcInformationByHcId',
    params: {
      ...data
    }
  })
}

export const saveMergeData = (data) => {
  return request({
    url: '/hcInformation/saveMergeData',
    method: 'POST',
    data
  })
}
export const getMergeData = (data) => {
  return request({
    url: '/hcInformation/getMergeData',
    method: 'POST',
    data
  })
}
export const getRevertMergeData = (data) => {
  return request({
    url: '/hcInformation/getRevertMergeData',
    params: {
      ...data
    }
  })
}
export const revertMergeData = (data) => {
  return request({
    url: '/hcInformation/revertMergeData',
    method: 'POST',
    data
  })
}

export const getClient = (data) => {
  return request({
    url: '/hrRafForm/getClient',
    params: {
      ...data
    }
  })
}
export const getLineManagerPerson = (data) => {
  return request({
    url: '/hrRafForm/getLineManager',
    params: {
      ...data
    }
  })
}
export const downloadSelect = (data) => {
  return $http({
    method:'POST',
    url:'/hrRafForm/select/download/list',
    responseType: 'arraybuffer',
    data
  })
}
export const downloadAll = () => {
  exportDownload('hrRafForm/download/list', {})
}
