<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <!--      <keep-alive>-->
      <router-view :key="key" />
      <!--      </keep-alive>-->
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>
<style lang="scss" scoped>
.app-main {
  //height: calc(100vh - 60px);
  box-sizing: border-box;
  position: relative;
  padding: 15px 15px 0 15px;
  //overflow-x: hidden;
  //overflow-y: auto;
}
//.overflowAuto {
//  overflow: auto;
//}
</style>
