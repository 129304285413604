export default {
  login: {},
  route: {
    home: '首页',
    systemManagement: '系统管理',
    userManagement: '用户管理',
    roleManagement: '角色管理',
    authorityManagement: '权限管理',
    codeManagement: '代码值管理',
    attendanceRecord: '实习生考勤记录',
    attendanceSummary: '实习生考勤汇总',
    attendance: '实习生考勤管理',
    orgManage: '组织管理',
    baseSetting: '基础设置',
    fundsManage: '社保/公积金管理',
    organization: '机构管理',
    employmentApplication: '用人申请',
    personnelRequisition: '人员申请',
    financialApproval: '财务审批',
    quotaManagement: '配额管理',
    recruitmentManagement: '招聘管理',
    candidateManagement: '候选人管理',
    intern: '实习生',
    internManage: '实习生管理',
    employees: '员工列表',
    employeeJoin: '员工入职',
    RecruitmentManagement: '招聘管理',
    salaryManagement: '薪资管理',
    pcTrackerManagemengt: 'PC Tracker',
    payRollManagement: 'Payroll Act',
    ctoApproval: 'CTO审批',
    PCTrackerMVPManagement: 'PC Tracker MVP',
    IC_Charge: 'IC Charge',
    TurnDownOfferRecord:'拒绝记录',
    itData: 'IT 数据',
    itEmployee: '正式员工',
    itIntern: '实习生',
    dashboard: 'PC Management',
    dashboardHome: 'PC Summary',
    agency: 'PC by Agency',
    client: 'PC by Client',
    project: 'PC by Project',
    projectPcRatio: 'PcRatio Management',
    projectRevenue: 'Revenue Management',
    capacityManagement: 'Capacity Management',
    capacityProject: 'Project',
    capacity: 'Capacity',
    humanProcess:'Human Process',
    projectManagement:'Projects',
    dashboardImport: 'Import Data',
  }
}
