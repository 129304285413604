export const financialQuota = [
  {
    path: '/employmentApplication/financialQuota',
    name: 'financialQuota',
    component: () => import('@/views/financialApproval/financialQuota.vue'),
    meta: {
      title: '招聘配额',
      hidden: true,
      activeMenu: '/employmentApplication/financialApproval'
    }
  }
]
