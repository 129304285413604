import dataMain from './dataMain.js'
import secretRole from './secretRole.js'
import sys from './sys.js'
import funds from './funds.js'
import attensum from './attensum.js'
import induction from './induction.js'
import candidate from './candidate.js'
import staff from './staff.js'
import salary from './salary'
export default {
  dataMain,
  secretRole,
  sys,
  funds,
  attensum,
  induction,
  candidate,
  staff,
  salary
}
