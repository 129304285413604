/**
 * 从url中通过参数名获取对应的值
 * @param {*} url 地址
 * @param {*} keyName 参数名
 * @returns
 */

export const titleCase = (str) => {
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
}
export const getQueryString = (url, keyName) => {
  const paramStr = url.split('?')[1]
  const reg = new RegExp('(^|&)' + keyName + '=([^&]*)(&|$)')
  const r = paramStr.match(reg)
  if (r != null) {
    return unescape(r[2])
  } else {
    return null
  }
}
/**
 * 刪除表格中指定列
 * @param {data} 表格集合
 * @param {row} 表格行
 * @returns
 */
export const deleteTableRow = (data, row) => {
  data.splice(
    data.findIndex((item) => {
      return item.id === row.id
    }),
    1
  )
  return data
}
/**
 * 自定义校验表单内容
 * @param {value} 校验值
 * @param {reg} 校验规则,默认校验非空
 * @returns
 */
export const regRequireForm = (value, reg) => {
  return !/^\s*$/g.test(value)
}
/**
 * 数字位数不够，前面位数补零
 * @param {num} 要补0的数字
 * @param {len} 补0后位数
 * @returns
 */
export const formatZero = (num, len) => {
  if (String(num).length > len) return num
  return (Array(len).join(0) + num).slice(-len)
}
/**
 * 刪除表格中指定列
 * @param {data} 表格集合
 * @param {row} 表格行
 * @returns
 */
export const getTheyearOptions = (num = 25) => {
  const list = []
  const nowYear = new Date().getFullYear()
  for (let i = 0; i < num; i++) {
    const item = { id: nowYear - i, name: nowYear - i }
    list.push(item)
  }
  return list
}

// 时间戳转化为年月日时分秒
export const stampToTime = (timestamp) => {
  const d = new Date(timestamp) // 根据时间戳生成的时间对象
  console.log('1212', d)
  const time =
    d.getFullYear() +
    '-' +
    checkAddZone(d.getMonth() + 1) +
    '-' +
    checkAddZone(d.getDate()) +
    ' ' +
    checkAddZone(d.getHours()) +
    ':' +
    checkAddZone(d.getMinutes()) +
    ':' +
    checkAddZone(d.getSeconds())
  return time
}
// 补0
const formatNumber = (n) => {
  n = n.toString()
  return n[1] ? n : '0' + n
}
const checkAddZone = (num) => {
  return num < 10 ? '0' + num.toString() : num
}
// 颜色渐变辅助函数
const colorRgb = (sColor) => {
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  sColor = sColor.toLowerCase()
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    // 处理六位的颜色值
    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
    }
    return sColorChange
  } else {
    return sColor
  }
}

// 颜色渐变辅助函数
// 将rgb表示方式转换为hex表示方式
const colorHex = (rgb) => {
  const _this = rgb
  const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  if (/^(rgb|RGB)/.test(_this)) {
    const aColor = _this.replace(/(?:(|)|rgb|RGB)*/g, '').split(',')
    let strHex = '#'
    for (let i = 0; i < aColor.length; i++) {
      let hex = Number(aColor[i]).toString(16)
      hex = hex < 10 ? 0 + '' + hex : hex // 保证每个rgb的值为2位
      if (hex === '0') {
        hex += hex
      }
      strHex += hex
    }
    if (strHex.length !== 7) {
      strHex = _this
    }
    return strHex
  } else if (reg.test(_this)) {
    const aNum = _this.replace(/#/, '').split('')
    if (aNum.length === 6) {
      return _this
    } else if (aNum.length === 3) {
      let numHex = '#'
      for (let i = 0; i < aNum.length; i += 1) {
        numHex += aNum[i] + aNum[i]
      }
      return numHex
    }
  } else {
    return _this
  }
}

/**
 * 开始颜色到结束颜色的渐变
 */
export const GradientColor = (startColor, endColor, step) => {
  const startRGB = colorRgb(startColor) // 转换为rgb数组模式
  const startR = startRGB[0]
  const startG = startRGB[1]
  const startB = startRGB[2]

  const endRGB = colorRgb(endColor)
  const endR = endRGB[0]
  const endG = endRGB[1]
  const endB = endRGB[2]

  const sR = (endR - startR) / step // 总差值
  const sG = (endG - startG) / step
  const sB = (endB - startB) / step

  const colorArr = []
  for (let i = 0; i < step; i++) {
    // 计算每一步的hex值
    const hex = colorHex(
      'rgb(' +
        parseInt(sR * i + startR) +
        ',' +
        parseInt(sG * i + startG) +
        ',' +
        parseInt(sB * i + startB) +
        ')'
    )
    colorArr.push(hex)
  }
  return colorArr
}
export const debounce = (func, delay, immediate = false) => {
  // result表示返回值
  let timeout, result

  const debounced = function () {
    // 存储触发当前事件的this
    const _this = this
    // 存储event对象
    const args = arguments
    clearTimeout(timeout)
    // 判断是否立即执行，如果不传默认不立即执行
    if (immediate) {
      // 立即执行
      const callNow = !timeout
      timeout = setTimeout(() => {
        timeout = null
      }, delay)
      if (callNow) result = func.apply(_this, args)
    } else {
      timeout = setTimeout(function () {
        func.apply(_this, args)
      }, delay)
    }
    return result
  }
  debounced.cancel = function () {
    clearTimeout(timeout)
    timeout = null
  }
  return debounced
}

export function check(obj) {
  return Object.prototype.toString.call(obj)
}
export const typeRange = {
  number: '[object Number]',
  string: '[object String]',
  boolean: '[object Boolean]',
  null: '[object Null]',
  undefined: '[object Undefined]',
  array: '[object Array]',
  object: '[object Object]',
  regExp: '[object RegExp]',
  function: '[object Function]'
}
/**
 * 判断是否为外部资源
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export function deepClone(val) {
  return { ...val }
}
export function throttle(func, delay) {
  let timer
  return function () {
    let context = this
    let args = arguments
    // 这里如果定时器存在，则返回, 就是当timer存在就不再触发事件
    if (timer) {
      return
    }
    timer = setTimeout(() => {
      func.apply(context, args)
      timer = null
    }, delay)
  }
}

export function mergeDesDataOfPort(desData, portData) {
  desData.forEach((item) => {
    if (item.content) {
      item.content.forEach((contentData) => {
        contentData.value = portData[contentData.key]
      })
    }
  })
}

//校验手机号码
export function checkPhone(mobile) {
  return /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(mobile);
}

//校验身份证
let checkIdCard = function (val) {
  let _IDRe18 =
    /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  let _IDre15 =
    /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
  //港澳通行证验证
  let hgidcrad = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/
  //台胞证验证
  let tbidcrad = /(^d{8})$/
  //回乡证
  let hxidcrad = /(H|M)(d{10})$/
  //台湾通行证 /[A-Z][0-9]{9}/
  let twidcrad = /^[a-zA-Z][0-9]{9}$/
  //护照验证
  let hzidcrad =
    /^1[45][0-9]{7}$|([P|p|S|s]\d{7}$)|([S|s|G|g]\d{8}$)|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|([H|h|M|m]\d{8,10})$/
  // 校验身份证：
  return (
    _IDRe18.test(val) ||
    _IDre15.test(val) ||
    hgidcrad.test(val) ||
    tbidcrad.test(val) ||
    hxidcrad.test(val) ||
    twidcrad.test(val) ||
    hzidcrad.test(val)
  )
}
export const camelize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
/**
 * 求百分比
 * @param  num 当前数
 * @param  total 总数
 */
export function getPercent(num, total) {
  num = parseFloat(num)
  total = parseFloat(total)
  if (isNaN(num) || isNaN(total)) {
    return '-'
  }
  let a = parseFloat(total) - parseFloat(num)
  let b = (a / parseFloat(num)) * 100
  return b.toFixed(2) + '%'
}
export function intervalTime(startTime, endTime) {
  // var timestamp=new Date().getTime(); //计算当前时间戳
  var timestamp = Date.parse(new Date()) / 1000 //计算当前时间戳 (毫秒级)
  var date1 = '' //开始时间
  if (timestamp < startTime) {
    date1 = startTime
  } else {
    date1 = timestamp //开始时间
  }
  var date2 = endTime //结束时间
  // var date3 = date2.getTime() - date1.getTime(); //时间差的毫秒数
  var date3 = (date2 - date1) * 1000 //时间差的毫秒数
  //计算出相差天数
  var days = Math.floor(date3 / (24 * 3600 * 1000))
  //计算出小时数

  var leave1 = date3 % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000))
  //计算相差分钟数
  var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000))

  //计算相差秒数

  var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
  var seconds = Math.round(leave3 / 1000)
  console.log(days + '天 ' + hours + '小时 ')
  // return   days + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒"

  return {
    days,
    hours,
    minutes,
    seconds
  }
}
export default checkIdCard


export function downLoadFileByBlob(res){
  const { data, headers } = res
  const fileType = headers["content-type"]; // 文件类型
  const fileName = decodeURI(headers["content-disposition"]).split(";")[1].split("=")[1]; // 文件名称
  // 创建a标签模拟点击
  const url = window.URL.createObjectURL(new Blob([data],{type:fileType})); // 注意第一个参数是数组形式
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// 浮点运算
// 乘法
export function NumberMul(arg1, arg2) {
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString();
  try {
      m += s1.split(".")[1].length;
  } catch (e) {}
  try {
      m += s2.split(".")[1].length;
  } catch (e) {}

  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}


// 加法
export function NumberAdd (arg1, arg2) {
  var r1, r2, m, n;
  try {
      r1 = arg1.toString().split(".")[1].length
  } catch (e) {
      r1 = 0
  }
  try {
      r2 = arg2.toString().split(".")[1].length
  } catch (e) {
      r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  n = (r1 >= r2) ? r1 : r2;
  return ((arg1 * m + arg2 * m) / m).toFixed(n);
}

// 减法
export function NumberSub  (arg1, arg2) {
  var re1, re2, m, n;
  try {
      re1 = arg1.toString().split(".")[1].length;
  } catch (e) {
  re1 = 0;
  }
  try {
      re2 = arg2.toString().split(".")[1].length;
  } catch (e) {
      re2 = 0;
  }
  m = Math.pow(10, Math.max(re1, re2));
  n = (re1 >= re2) ? re1 : re2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

// 除法
export function NumberDiv  (arg1, arg2) {
  var t1=0,t2=0,r1,r2;
  try{t1=arg1.toString().split(".")[1].length}catch(e){}
  try{t2=arg2.toString().split(".")[1].length}catch(e){}
  r1=Number(arg1.toString().replace(".",""))
  r2=Number(arg2.toString().replace(".",""))
  //获取小数点后的计算值
  var result= ((r1/r2)*Math.pow(10,t2-t1)).toString()
  var result2=result.split(".")[1];
  result2=result2.substring(0,digit>result2.length?result2.length:digit);

  return Number(result.split(".")[0]+"."+result2);
}

// 解决滚动浏览器抖动问题
export function ScrollRaf(after) {
  var isScroll;
  return function() {
    if (isScroll) return;
    isScroll = true;
    requestAnimationFrame(function() {
        after && after();
        isScroll = false;
    });
  }
}

export function upCase(str) {
  return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}
