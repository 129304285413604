export const intern = [
  {
    path: '/intern/newIntern',
    name: 'newIntern',
    component: () => import('@/views/intern/pages/NewIntern.vue'),
    meta: {
      title: '新建实习生',
      hidden: true,
      activeMenu: '/intern/internManage'
    }
  },
  {
    path: '/intern/EditIntern',
    name: 'EditIntern',
    component: () => import('@/views/intern/pages/EditIntern.vue'),
    meta: {
      title: '编辑实习生',
      hidden: true,
      activeMenu: '/intern/internManage'
    }
  },
  {
    path: '/intern/internDetails',
    name: 'internDetails',
    component: () => import('@/views/intern/pages/internDetails.vue'),
    meta: {
      title: '实习生详情',
      hidden: true,
      activeMenu: '/intern/internManage'
    }
  }
]
