<template>
  <el-scrollbar class="poc-scrollbar-default" id="poc-scrollbar-default">
    <sidebar-menu></sidebar-menu>
    <div class="menu-left-operation">
      <div class="menu-left-btn" @click="onCollapse">
        <i
          class="poc-icon-primary poc-size16 menu-left-icon"
          :class="
            $store.getters.sidebarOpened ? 'el-icon-s-unfold' : 'el-icon-s-fold'
          "
        ></i>
      </div>
    </div>
  </el-scrollbar>
</template>
<script>
import SidebarMenu from './SidebarMenu.vue'
export default {
  name: 'sidebar',
  components: {
    SidebarMenu
  },
  methods: {
    onCollapse() {
      this.$store.commit('app/triggerSidebarOpened')
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-left-operation {
  height: 32px;
  padding: 0 $MP8;
  overflow: hidden;
  .menu-left-btn {
    position: relative;
    height: 100%;
    cursor: pointer;
    border-top: 1px solid $poc-border-color;
  }
  .menu-left-icon {
    position: absolute;
    top: 8px;
    right: calc(50% - 8px);
    font-size: 16px;
  }
}
::v-deep .el-scrollbar__view{
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
</style>
