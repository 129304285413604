// token
export const TOKEN = 'iriseToken'
// token 时间戳
export const TIME_STAMP = 'timeStamp'
// 超时时长(毫秒) 两小时
export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000
// 国际化
export const LANG = 'iriseLanguage'

export const USERINFO = 'userInfo'

export const POINTLIST = 'pointList'
