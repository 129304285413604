import {
  getStartData,
  getSubTotalBasePayroll,
  getHCTotal,
  getEnglishName,
  getSubTotal13thPayroll,
  getSubTotalHolidayPay,
  getSubTotalSalesCommOp113,
  getSubTotalSocialChargesBase,
  getSubTotalSocialChargesOp121,
  getSubTotalMedicalInsuranceOp126,
  getSubTotalHomeLeaveOp132,
  getSubTotalHousingSchoolFeeOthersOp132,
  getSubTotalRelocationOp132,
  getSubTotalFellingfeeOthersOp132,
  getSubTotalSeveranceOp141FullYear,
  getSubTotalBonusOp162Op164FullYear,
  getCommInsurancOPData
} from '@/views/salaryManagement/components/modal/ChargeOutDialog/mixin/FormateHiddenTableColumn'
import { changeHCDataYear } from '@/views/salaryManagement/editionData/addColumnConfig/HCData'
import { getItem } from '@/utils/storage'
import { ops131CostTableDataConfig } from '@/views/salaryManagement/editionData/ops131tableData'

export const cutExpend = (val, xTable4, names) => {
  const fields = names
  val.collapsable = !val.collapsable
  fields.forEach((field) => {
    const column = xTable4.getColumnByField(field)
    column.visible = val.collapsable
  })
  xTable4.refreshColumn()
}
export const chargeOutColumn = [
  // {
  //   key: 'hcNature',
  //   name: 'HC Nature',
  //   dataType: 'string',
  //   visible: true,
  //   sortable: false,
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'left',
  //   fold: false,
  //   width: 100 // 默认宽度，像素
  // },
  {
    key: 'englishName',
    name: 'English Name',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: true,
    collapsable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getEnglishName().names)
    },
    width: 140 // 默认宽度，像素
  },
  //导入English Name折叠内容
  ...getEnglishName().column,
  {
    key: 'startDate',
    name: 'Start Date',
    dataType: 'string',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'left',
    fold: true,
    collapsable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getStartData().names)
    },
    width: 130 // 默认宽度，像素
  },
  //  //导入startDate折叠内容
  ...getStartData().column,
  {
    key: 'totalHc',
    name: 'HC Total',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    fold: true,
    collapsable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getHCTotal().names)
    },
    width: 100 // 默认宽度，像素
  },
  //导入HC Total折叠内容
  ...getHCTotal().column,
  {
    key: 'totalCostJan',
    childrenKey: 'totalCostJanPer',
    childrenForKey: 'totalCostJanForest',
    moreRow: true, //需要处理多行
    monthName: 1,
    name: 'Total Cost Jan-',
    dataType: 'number',
    changeYear: true,
    visible: true,
    sortable: false,
    fold: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150
  },
  {
    key: 'totalCostFeb',
    childrenKey: 'totalCostFebPer',
    childrenForKey: 'totalCostFebForest',
    moreRow: true, //需要处理多行
    monthName: 2,
    name: 'Total Cost Feb-',
    dataType: 'number',
    changeYear: true,
    fold: false,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
    //moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostMar',
    childrenKey: 'totalCostMarPer',
    childrenForKey: 'totalCostMarForest',
    moreRow: true, //需要处理多行
    monthName: 3,
    fold: false,
    name: 'Total Cost Mar-',
    dataType: 'number',
    visible: true,
    changeYear: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
    //moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostApr',
    childrenKey: 'totalCostAprPer',
    childrenForKey: 'totalCostAprForest',
    moreRow: true, //需要处理多行
    monthName: 4,
    name: 'Total Cost Apr-',
    dataType: 'number',
    fold: false,
    changeYear: true,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
    //moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostMay',
    childrenKey: 'totalCostMayPer',
    childrenForKey: 'totalCostMayForest',
    fold: false,
    moreRow: true, //需要处理多行
    monthName: 5,
    name: 'Total Cost May-',
    dataType: 'number',
    changeYear: true,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
    //  moreRow:true,//需要处理多行
  },

  {
    key: 'totalCostJun',
    childrenKey: 'totalCostJunPer',
    childrenForKey: 'totalCostJunForest',
    fold: false,
    moreRow: true, //需要处理多行
    monthName: 6,
    name: 'Total Cost Jun-',
    dataType: 'number',
    changeYear: true,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostJul',
    childrenKey: 'totalCostJulPer',
    childrenForKey: 'totalCostJulForest',
    fold: false,
    moreRow: true, //需要处理多行
    monthName: 7,
    name: 'Total Cost Jul-',
    dataType: 'number',
    changeYear: true,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostAug',
    childrenKey: 'totalCostAugPer',
    childrenForKey: 'totalCostAugForest',
    fold: false,
    moreRow: true, //需要处理多行
    monthName: 8,
    name: 'Total Cost Aug-',
    dataType: 'number',
    changeYear: true,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostSep',
    childrenKey: 'totalCostSepPer',
    childrenForKey: 'totalCostSepForest',
    fold: false,
    moreRow: true, //需要处理多行
    monthName: 9,
    name: 'Total Cost Sep-',
    dataType: 'number',
    changeYear: true,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostOct',
    childrenKey: 'totalCostOctPer',
    childrenForKey: 'totalCostOctForest',
    fold: false,
    moreRow: true, //需要处理多行
    monthName: 10,
    name: 'Total Cost Oct-',
    dataType: 'number',
    changeYear: true,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostNov',
    childrenKey: 'totalCostNovPer',
    fold: false,
    childrenForKey: 'totalCostNovForest',
    moreRow: true, //需要处理多行
    monthName: 11,
    name: 'Total Cost Nov-',
    dataType: 'number',
    changeYear: true,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'totalCostDec',
    childrenKey: 'totalCostDecPer',
    fold: false,
    childrenForKey: 'totalCostDecForest',
    moreRow: true, //需要处理多行
    monthName: 12,
    name: 'Total Cost Dec-',
    dataType: 'number',
    changeYear: true,
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 150 // 默认宽度，像素
    // moreRow:true,//需要处理多行
  },
  {
    key: 'salaryFullYear',
    name: 'Salary Full year',
    fold: false,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 140 // 默认宽度，像素
  },

  {
    key: 'subTotalBasePayrollOp111',
    name: 'Sub-Total Base payroll',
    dataType: 'number',
    visible: true,
    sortable: false,
    fold: true,
    collapsable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotalBasePayroll().names)
    },
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  ...getSubTotalBasePayroll().column,
  {
    key: 'subTotalThirteenPayrollOp111',
    name: 'Sub-Total 13th payroll',
    dataType: 'number',
    visible: true,
    sortable: false,
    fold: true,
    collapsable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotal13thPayroll().names)
    },
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  ...getSubTotal13thPayroll().column,
  {
    key: 'subTotalHolidayPayOp112',
    name: 'Sub-Total Holiday pay',
    fold: true,
    collapsable: false,
    dataType: 'number',
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotalHolidayPay().names)
    },
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 180 // 默认宽度，像素
  },
  ...getSubTotalHolidayPay().column,
  {
    key: 'subTotalSalesCommOp113',
    name: 'Sub-Total Sales Comm-OP113',
    fold: true,
    collapsable: false,
    dataType: 'number',
    visible: true,
    sortable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotalSalesCommOp113().names)
    },
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 260 // 默认宽度，像素
  },
  ...getSubTotalSalesCommOp113().column,
  // {
  //   key: 'subTotalSocialChargesBase',
  //   name: 'Sub-Total Social Charges',
  //   dataType: 'number',
  //   fold: true,
  //   collapsable: false,
  //   visible: true,
  //   sortable: false,
  //   collapsableEvent: function (val, xTable4) {
  //     cutExpend(val, xTable4, getSubTotalSocialChargesBase().names)
  //   },
  //   fixed: false, // 固定列的位置(left, right)
  //   align: 'right',
  //   width: 260 // 默认宽度，像素
  // },
  {
    key: 'socialChargesBase',
    name: 'Social Charges base',
    fold: false,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 140 // 默认宽度，像素
  },
  {
    key: 'housingFundBase',
    name: 'Housing Fund base',
    fold: false,
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 140 // 默认宽度，像素
  },
  // ...getSubTotalSocialChargesBase().column,
  {
    key: 'subTotalSocialChargesOp121',
    name: 'Sub-Total Social ChargesOp121',
    fold: true,
    collapsable: false,
    dataType: 'number',
    visible: true,
    sortable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotalSocialChargesOp121().names)
    },
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 260 // 默认宽度，像素
  },
  ...getSubTotalSocialChargesOp121().column,
  {
    key: 'subTotalMedicalInsuranceOp126',
    name: 'Sub-Total Medical Insurance',
    dataType: 'number',
    fold: true,
    collapsable: false,
    visible: true,
    sortable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotalMedicalInsuranceOp126().names)
    },
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 260 // 默认宽度，像素
  },
  ...getSubTotalMedicalInsuranceOp126().column,
  {
    key: 'subTotalHomeLeaveOp132',
    name: 'Sub-Total HomeLeave',
    dataType: 'number',
    visible: true,
    fold: true,
    collapsable: false,
    sortable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotalHomeLeaveOp132().names)
    },
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 260 // 默认宽度，像素
  },
  ...getSubTotalHomeLeaveOp132().column,
  {
    key: 'subTotalHousingSchoolFeeOthersOp132',
    name: 'Sub-Total Housing School Fee',
    fold: true,
    collapsable: false,
    dataType: 'number',
    visible: true,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotalHousingSchoolFeeOthersOp132().names)
    },
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 260 // 默认宽度，像素
  },
  ...getSubTotalHousingSchoolFeeOthersOp132().column,
  {
    key: 'subTotalRelocationOp132',
    name: 'Sub-Total Relocation',
    dataType: 'number',
    visible: true,
    fold: true,
    collapsable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotalRelocationOp132().names)
    },
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 260 // 默认宽度，像素
  },
  ...getSubTotalRelocationOp132().column,
  {
    key: 'subTotalFellingfeeOthersOp132',
    name: 'Sub-Total Filling Fee&Others',
    dataType: 'number',
    visible: true,
    fold: true,
    collapsable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotalFellingfeeOthersOp132().names)
    },
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 260 // 默认宽度，像素
  },
  ...getSubTotalFellingfeeOthersOp132().column,
  {
    key: 'subTotalSeveranceOp141FullYear',
    name: 'Sub-Total Severance',
    dataType: 'number',
    visible: true,
    fold: true,
    collapsable: false,
    sortable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotalSeveranceOp141FullYear().names)
    },
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 260 // 默认宽度，像素
  },
  ...getSubTotalSeveranceOp141FullYear().column,
  {
    key: 'subTotalBonusOp162Op164FullYear',
    name: 'Sub-Total Bonus',
    dataType: 'number',
    visible: true,
    fold: true,
    collapsable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getSubTotalBonusOp162Op164FullYear().names)
    },
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 260 // 默认宽度，像素
  },
  ...getSubTotalBonusOp162Op164FullYear().column,
  {
    key: 'subTotalCommInsuranceIitOp126FullYear',
    name: 'Sub-Total CommInsuranceIITOp126',
    dataType: 'number',
    visible: true,
    fold: true,
    collapsable: false,
    collapsableEvent: function (val, xTable4) {
      cutExpend(val, xTable4, getCommInsurancOPData().names)
    },
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 280 // 默认宽度，像素
  },
  ...getCommInsurancOPData().column
]
export const changeChargeOutYear = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    chargeOutColumn.forEach((item) => {
      if(item.fold){
        item.collapsable=false
      }
      if (item.changeYear) {
        let name = item.name.split('-')[0]
        item.name = name + '-' + year
      }
    })
  }
}
