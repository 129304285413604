import { getItem } from '@/utils/storage'

export const basePayRollData = [
  {
    key: 'basePayrollOp111Jan',
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'basePayrollOp111Feb',
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'basePayrollOp111Mar',
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'basePayrollOp111Apr',
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'basePayrollOp111May',
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'basePayrollOp111Jun',
    name: 'Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'basePayrollOp111Jul',
    name: 'Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'basePayrollOp111Aug',
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'basePayrollOp111Sep',
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'basePayrollOp111Oct',
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'basePayrollOp111Nov',
    name: 'Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'basePayrollOp111Dec',
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  }
]
export const changeBasePayRollDataYear = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    basePayRollData.forEach((item) => {
      let name = item.name.split('-')[0]
      item.name = name + '-' + year
    })
  }
}
basePayRollData.map((v) => {
  if (v.name != 'Salary Full Year') {
    v.name = 'basePayRoll' + v.name
  }
})
