import store from '@/store'
import router from '@/router'
const { $http } = $PCommon
import { Message } from 'element-ui'

const service = $http.axios
service.defaults.baseURL = process.env['VUE_APP_BASE_API'] + '/api/iris' // api/nova
// service.defaults.baseURL = 'http://10.146.27.73:9092' // api/nova
// service.defaults.baseURL = 'https://iris-test.irisgrowth.com/api/iris'
// service.defaults.baseURL = process.env['VUE_APP_BASE_API']
service.defaults.timeout = 30000 // 60s
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 统一的去注入token
    if (store.getters.token) {
      // 如果token存在 注入token
      config.headers.token = `${store.getters.token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const { status, msg, data,config, statusText } = response
    //   要根据success的成功与否决定下面的操作
    if (status === 200) {
      if (config.responseType === 'arraybuffer'){
        return response
      }else if (data) {
        if (data.code === 200) {
          return data
        } else if (data.code === 201) {
          Message(data.msg)
          return
        } else if (data.code === 403) {
          store.dispatch('user/logout')
          Message.error(data.msg) // 提示错误消息
        } else if (data.code === 500) {
          // router.replace({ path: '/500' })
          if(router.currentRoute.path == '/pcTracker/payroll' && config.url === '/payrollDetail/upload') {
            Message({
              type: 'error',
              message: data.msg,
              duration: 5000
            })
          } else {
            Message.error(data.msg) // 提示错误消息
          }
          return data
        }
      } else {
        Message.error('无返回数据') // 提示错误消息
      }
    } else {
      // 业务错误
      Message.error(msg) // 提示错误消息
      return Promise.reject(new Error(msg))
    }
  },
  (error) => {
    if (error.request.status === 500) {
      if (error.response.data.code === 403) {
        store.dispatch('user/logout')
        Message.error(data.msg) // 提示错误消息
      } else {
        // router.replace({ path: '/500' })
      }
    }
    // 处理 token 超时问题
    Message.error(error.msg) // 提示错误信息
    return Promise.reject(error)
  }
)
export default service

export const $httpOrigin = $http
