import { LANG } from '@/constant'
import { getItem, setItem } from '@/utils/storage'
import { check, typeRange } from '@/utils/util'
import { staffInfoCount } from '@/api/employees'
export default {
  namespaced: true,
  state: () => ({
    staffInfoCount: {
      onTheJobCount: getItem('onTheJobCount') || 0,
      toBeEmployedCount: getItem('toBeEmployedCount') || 0,
      resignCount: getItem('resignCount') || 0,
      changeManagementCount: getItem('changeManagementCount') || 0,
      changeManagementTransferCount: getItem('changeManagementTransferCount') || 0,
      changeManagementPromotionCount: getItem('changeManagementPromotionCount') || 0,
      changeManagementResignCount: getItem('changeManagementResignCount') || 0,
      changeManagementPositiveCount: getItem('changeManagementPositiveCount') || 0,
      changeManagementRenewCount: getItem('changeManagementRenewCount') || 0
    }
  }),
  mutations: {
    setStaffInfoCount(state, obj) {
      if (obj && check(obj) === typeRange.object) {
        Object.keys(state.staffInfoCount).forEach((item) => {
          setItem(item, obj[item])
          state.staffInfoCount[item] = obj[item]
        })
      }
    }
  },
  actions: {
    getStaffInfoCount(context, payload) {
      staffInfoCount().then((res) => {
        if (res && res.code === 200) {
          context.commit('setStaffInfoCount', res.data)
        }
      })
    }
  }
}
