import $http, { $httpOrigin } from '@/utils/request'
export default {
  // 测试用 删除接口
  deletePctracker(params) {
    return $http.get('/pctrackerVersion/delete', { params })
  },
  // 首页的数据列表(当前版本、历史版本、汇总版本明细)
  getDataList(params) {
    return $http.get('/pctrackerVersion/versionList', {
      params
    })
  },
  // 首页汇总版本的汇总
  getGatherDataList(params) {
    return $http.get('/pctrackerVersion/summaryVersionList', {
      params
    })
  },
  getOp111Table(params) {
    return $http.get('/pctrackerVersion/opNowPage', {
      params
    })
  },
  //动态pc
  getOp111TableNew(params) {
    return $http.get('/dynamicPctracker/detail', {
      params
    })
  },
  //动态pc
  getOp111TableNewCEO(params) {
    return $http.get('/dynamicPctracker/ceo/detail', {
      params
    })
  },
  getHistoryOp111Table(params) {
    return $http.get('/pctrackerVersion/op111HistoryPage', {
      params
    })
  },
  getSumInSumOp111Table(params) {
    return $http.get('/pctrackerVersion/op111SumPage', {
      params
    })
  },
  // 获取151、152的table
  getOp1512Table(params) {
    return $http.get('/pctrackerOp151152/list', {
      params
    })
  },
  // 获取151、152的table---汇总版本中的汇总
  getsumInsumOp1512Table(params) {
    return $http.get('/pctrackerOp151152/summaryList', {
      params
    })
  },
  // 获取summary详情
  getSumaryDetail(params) {
    return $http.get('/pctrackerVersion/sumVersionSummaryDetail', {
      params
    })
  },
  getSumVersionSummaryHistoryDetail(params) {
    return $http.get('/pctrackerVersion/sumVersionSummaryHistoryDetail', {
      params
    })
  },
  editSumVersionSummaryDetail(params) {
    return $http.post('/pctrackerVersion/editSumVersionSummaryDetail', params)
  },
  saveRfReleated(params) {
    return $http.post('/dynamicPctracker/saveRfReleated', params)
  },
  getHistoryOp1512Table(params) {
    return $http.get('/pctrackerOp151152/historyList', {
      params
    })
  },
  //commit名字数据
  getVersionList(params) {
    return $http.get('/pctrackerVersion/chooseVersionList', {
      params
    })
  },
  //新建版本
  addEdition(params) {
    return $http.post('/pctrackerVersion/addVersion', params)
  },
  //新建版本
  chooseAddEdition(params) {
    return $http.post('/pctrackerVersion/createVersion', params)
  },
  //获得benchMark列表
  getBenchMarkList(params) {
    return $http.get('/pctrackerVersion/benchMark', {
      params
    })
  },
  //编辑op111的时间
  editOp111StartDate(params) {
    return $http.post('/pctrackerVersion/editStartDate', params)
  },
  //编辑op111的合计
  editOp111Sum(params) {
    return $http.post('/pctrackerVersion/editOpNowSummary', params)
  },
  //finance和BpLeader驳回
  financeBPLeaderReject(params) {
    return $http.get('/pctrackerVersion/reject', {
      params
    })
  },
  // 汇总驳回
  sumReject(params) {
    return $http.get('/pctrackerVersion/sumVersionDetailReject', {
      params
    })
  },
  //汇总版本--明细列表--汇总
  detailInSumforSum(params) {
    return $http.post('/pctrackerVersion/detailVersionSummary', params)
  },
  //获取financeList
  getFinanceList(params) {
    return $http.get('/pctrackerVersion/financeUserList', {
      params
    })
  },

  //汇总版本--汇总列表--提交
  submitSum(params) {
    return $http.post('/pctrackerVersion/summaryVersionSubmit', params)
  },
  //BPLeader提交
  BPLeaderSubmit(params) {
    return $http.get('/pctrackerVersion/bpLeaderApproval', {
      params
    })
  },
  // finance汇总的提交
  financeApproval(params) {
    return $http.get('/pctrackerVersion/financeApproval', {
      params
    })
  },
  //当前版本--提交汇总
  nowSubmitSum(params) {
    return $http.get('/pctrackerVersion/nowVersionSubmit', {
      params
    })
  },
  //获得op151查询
  getCostDetail(params) {
    console.log('666666666666', params)
    return $http.get('/pctrackerOp151152/costDetail', {
      params
    })
  },
  //编辑修改151
  editFreeLancer(params) {
    return $http.post('/pctrackerOp151152/editFreelancer', params)
  },
  //获得OP151&&152的总计
  getOp1512Totalcost(params) {
    // return $http.get('/pctrackerVersion/totalCostAll', {
    return $http.get('/dynamicPctracker/totalCostAll', {
      params
    })
  },
  //获得OP111的总计
  getOp111Sum(params) {
    return $http.get('/pctrackerVersion/op111Sum', {
      params
    })
  },
  //历史版本OP111TotalCost查询
  op111HistorySum(params) {
    return $http.get('/pctrackerVersion/op111HistorySum', {
      params
    })
  },
  //当前版本的提交汇总
  nowEditionSub(params) {
    return $http.get('/pctrackerVersion/nowVersionSubmit', {
      params
    })
  },
  // op111调整查询界面
  getOp111Detail(params) {
    return $http.get('/pctrackerVersion/opNowDetail', {
      params
    })
  },
  //编辑调整页面
  editNowOp111Detail(params) {
    return $http.post('/pctrackerVersion/editNowOp111Detail', params)
  },

  // 回收
  backEmpoyee(params) {
    return $http.get('/staff/takeBack', {
      params
    })
  },
  // 离职确认
  sureResign(params) {
    return $http.get('/staff/resignConfirm', {
      params
    })
  },
  //获得分配的人
  getAssignUser(params) {
    return $http.get('/staff/getUserList', {
      params
    })
  },
  //分配功能
  assignStaff(params) {
    return $http.get('/staff/distribute', { params })
  },
  //通知IT
  noticeIT(params) {
    return $http.post('/staff/notifyItAdmin', params)
  },
  // 获取详情信息
  getBascMsg({ id }) {
    return $http.get('/staff/basic', {
      params: {
        id
      }
    })
  },
  //获取职位信息
  getPositionMsg({ id }) {
    return $http.get('/staff/job', {
      params: {
        id
      }
    })
  },
  //获取合同信息
  getContactMsg({ id }) {
    return $http.get('/staff/contract', {
      params: {
        id
      }
    })
  },
  getSalaryMsg({ id }) {
    return $http.get('/staff/salary', {
      params: {
        id
      }
    })
  },
  //获取教育信息
  getEducationMsg({ id }) {
    return $http.get('/staff/education', {
      params: {
        id
      }
    })
  },

  //获取离职信息
  getLeaveMsg({ id }) {
    return $http.get('/staff/resignDetail', {
      params: {
        id
      }
    })
  },
  //获取工作经历
  getworkMsg({ vo }) {
    return $http.get('/staff/employmentHistory', {
      params: {
        vo
      }
    })
  },
  //删除工作经历
  deleteEmploymentHistory(id) {
    return $http.get('/staff/deleteEmploymentHistory', {
      params: id
    })
  },
  //删除教育信息
  deleteEducation(id) {
    return $http.get('/staff/deleteEducation', {
      params: id
    })
  },
  // 编辑教育信息
  editEducation(params) {
    return $http.post('/staff/editEducation', params)
  },
  //编辑工作经历
  editEmploymentHistory(params) {
    return $http.post('/staff/editEmploymentHistory', params)
  },
  //编辑薪水信息
  editSalary(params) {
    return $http.post('/staff/editStaffSalary', params)
  },
  downloadFile({ type, ids }) {
    // console.log('00000',`/api/iris/staff/staffTemplateDownPackage?flag=${type}&ids=${ids}&token=${localStorage.iriseToken}`)
    // return
    window.location = `/api/iris/staff/staffTemplateDownPackage?flag=${type}&ids=${ids}&token=${localStorage.iriseToken}`
  },
  downloadSingleFile({ type, id }) {
    window.location = `/api/iris/staff/staffTemplateDownload?flag=${type}&id=${id}&token=${localStorage.iriseToken}`
  },
  // 查询版本详情
  getVersionDetail(id) {
    return $http.get(`/pctrackerVersion/versionDetail?id=${id}`);
  }
}
