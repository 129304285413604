import { LANG } from '@/constant'
import { getItem, setItem } from '@/utils/storage'
import { staffInfoCount } from '@/api/employees'
export default {
  namespaced: true,
  state: () => ({
    sidebarOpened: true,
    language: getItem(LANG) || 'zh',
    indexYear: ''
  }),
  mutations: {
    triggerSidebarOpened(state) {
      state.sidebarOpened = !state.sidebarOpened
    },
    /**
     * 设置国际化
     */
    setLanguage(state, lang) {
      setItem(LANG, lang)
      state.language = lang
    },
    setIndexYear(state, indexYear) {
      state.indexYear = indexYear
    }
  },
  actions: {
    changeIndexYear(context, payload) {
      context.commit('setIndexYear', payload)
    }
  }
}
