import user from '@/store/modules/user'
import $http, { $httpOrigin } from '@/utils/request'

export default {
  // 新增应用
  addUser({
    assignedGroupList,
    assignedRoleList,
    beginTime,
    email,
    endTime,
    mobile,
    userCode,
    userName,
    id,
    oldUserId,
    status,
    userTypeList,
    remark
  }) {
    return $http.post('/sysUser/save', {
      assignedGroupList,
      assignedRoleList,
      beginTime,
      email,
      endTime,
      mobile,
      userCode,
      userName,
      id,
      oldUserId,
      status,
      userTypeList,
      remark
    })
  },
  // 导出日志
  exportDataList({
    failtures,
    status,
    roleName,
    mobile,
    userName,
    pageNum,
    pageSize
  }) {
    return $httpOrigin.downloadFile('/sysUser/export', {
      isEscape: true,
      params: {
        failtures,
        status,
        roleName,
        mobile,
        userName,
        pageNum,
        pageSize
      }
    })
  },
  exportDataList2({
    failtures,
    status,
    roleName,
    mobile,
    userName,
    pageNum,
    pageSize
  }) {
    window.location = `/api/iris/sysUser/export?failtures=${failtures}&status=${status}&roleName=${roleName}&mobile=${mobile}
    &userName=${userName}&pageNum=${pageNum}&pageSize=${pageSize}&token=${localStorage.iriseToken}`
  },

  // 给用户授权
  authorAddress({ addresses, userVo }) {
    return $http.post('/user/bindAddress', { addresses, userVo })
  },
  // 修改用户信息
  editUser({
    beginTime,
    email,
    endTime,
    mobile,
    userCode,
    userName,
    status,
    remark,
    id
  }) {
    return $http.post('/sysUser/update', {
      beginTime,
      email,
      endTime,
      mobile,
      userCode,
      userName,
      status,
      remark,
      id
    })
  },
  // 启用停用
  changeRowStatus({ id, status }) {
    return $http.post('/sysUser/changeStatus', { id, status })
  },
  // 解除锁定用户
  lockUser({ id, failtures }) {
    return $http.post('/sysUser/resetLockStatus', { id, failtures })
  },

  // 获取基础数据列表
  getDataList(
    params
  ) {
    return $http.post('/sysUser/list',  
      params
    )

  },
  // 获取用户类型列表
  getuserTypeList({}) {
    return $http.get('/sysUser/getUserTypeList', {
      params: {}
    })
  },
  // 获取弹出层组织机构待分配列表
  getOrgUnAssignList(userId) {
    return $http.get('/sysUser/getOrgUnAssignList', {
      params: {
        userId
      }
    })
  },
  // 获取弹出层组织机构已分配列表
  getOrgAssignList(userId) {
    return $http.get('/sysUser/getOrgAssignList', {
      params: {
        userId
      }
    })
  },
  // 获取弹出层角色待分配列表
  getRoleUnAssignList(userId) {
    return $http.get('/sysUser/getRoleUnAssignList', {
      params: {
        userId
      }
    })
  },
  // 获取弹出层角色已分配列表
  getRoleAssignList(userId) {
    console.log('89888888888', userId)
    return $http.get('/sysUser/getRoleAssignList', {
      params: {
        userId
      }
    })
  },
  // 获取单个用户信息
  getSingleMsg({ id }) {
    return $http.get('/sysUser/get', {
      params: {
        id
      }
    })
  },

  // 获取已分配机构
  getOrgList({ userId, pageNum, pageSize }) {
    return $http.get('/sysUser/getOrgList', {
      params: {
        userId,
        pageNum,
        pageSize
      }
    })
  },
  // 获取已分配角色
  getRoleList({ userId, pageNum, pageSize }) {
    return $http.get('/sysUser/getRoleList', {
      params: {
        userId,
        pageNum,
        pageSize
      }
    })
  },
  // 获取已分配权限
  getPermissionList({ userId, pageNum, pageSize }) {
    return $http.get('/sysUser/getPermissionList', {
      params: {
        userId,
        pageNum,
        pageSize
      }
    })
  },
  // 获取权限信息列表
  getscopeList() {
    return $http.get('/appInfo/getAppList', {
      params: {}
    })
  },
  // 删除排期
  deleteRow(ids) {
    return $http.post('/sysUser/delete', ids)
  },
  // 获取媒体类型
  getMediatTypeList({ keyword, userid }) {
    return $http.get('/DataSource/GetMediatTypeList', {
      params: {
        userid,
        pageNum: 1,
        pageSize: 1000,
        keyword
      }
    })
  },
  // 获取排期状态
  getProcessNodes({ processid }) {
    return $http.get('/DataSource/GetProcessNodes', {
      params: {
        processid
      }
    })
  },
  // 排期已有po查询
  getPOListFromOrderId({ Filter, pageNum, pageSize }) {
    return $http.post('/Order/GetPOListFromOrderId', {
      Filter,
      pageNum,
      pageSize
    })
  },
  // 排期已有po查询
  getSelectPOList({ Order, Filter, pageNum, pageSize }) {
    return $http.post('/Order/SelectPOList', {
      Order,
      Filter,
      pageNum,
      pageSize
    })
  },
  /**
   * 上传po附件
   * @param {FormData} formData 可以提交文件的FormData表单对象
   */
  uploadPo(formData) {
    return $http.post('/Attachment/Upload', formData, {
      upload: true,
      timeout: 24 * 60 * 60 * 1000
    })
  },
  // 保存po
  savePo(poform) {
    return $http.post('/Order/SavePO', { ...poform })
  },
  // 获取完整路径
  getPreviewUrl({ filePath }) {
    return $http.get('/Attachment/GetDowonloadUrl', {
      params: {
        filePath
      }
    })
  },
  // 获取排期管理(待办、已处理、我的、所有)总数
  getOrderListTotal() {
    return $http.get('/Order/GetOrderListTotal')
  },
  // 排期po保存
  orderPOSave({ OrderId, PoList }) {
    return $http.post('/Order/OrderPOSave', { OrderId, PoList })
  },
  resetPasswod(poform) {
    return $http.post('/sysUser/resetPasswod', { ...poform })
  }
}
