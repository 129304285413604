import layout from '@/layout'

export default {
  path: '/dashboard/pcManagement',
  component: layout,
  redirect: '/dashboard/pcmgt',
  name: 'dashboard',
  meta: {
    title: 'dashboard',
    icon: 'pctracker'
  },
  children: [
    {
      path: '/dashboard/pcmgt',
      name: 'dashboardHome',
      // component: () => import('@/views/Dashboard/index.vue'),
      meta: {
        title: 'dashboardHome',
        icon: '#'
      }
    },
    {
      path: '/dashboard/pcmgt/agency',
      name: 'agency',
      // component: () => import('@/views/Dashboard/agency.vue'),
      meta: {
        title: 'agency',
        icon: '#'
      }
    },
    {
      path: '/dashboard/pcmgt/client',
      name: 'client',
      // component: () => import('@/views/Dashboard/client.vue'),
      meta: {
        title: 'client',
        icon: '#'
      }
    },
    {
      path: '/dashboard/pcmgt/project',
      name: 'project',
      // component: () => import('@/views/Dashboard/project.vue'),
      meta: {
        title: 'project',
        icon: '#'
      }
    },
    {
      path: '/dashboard/pcmgt/projectRevenue',
      name: 'projectRevenue',
      // component: () => import('@/views/Dashboard/projectRevenue.vue'),
      meta: {
        title: 'projectRevenue',
        icon: '#'
      }
    },
    {
      path: '/dashboard/pcmgt/projectPcRatio',
      name: 'projectPcRatio',
      // component: () => import('@/views/Dashboard/projectPcRatio.vue'),
      meta: {
        title: 'projectPcRatio',
        icon: '#'
      }
    }
  ]
}
