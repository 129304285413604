import { getItem } from '@/utils/storage'
import { schoolFeeData } from '@/views/salaryManagement/editionData/addColumnConfig/schoolFeeData'

export const relocationData = [
  {
    key: 'relocationOp132Jan',
    name: 'Jan-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'relocationOp132Feb',
    name: 'Feb-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'relocationOp132Mar',
    name: 'Mar-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'relocationOp132Apr',
    name: 'Apr-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'relocationOp132May',
    name: 'May-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'relocationOp132Jun',
    name: 'Jun-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'relocationOp132Jul',
    name: 'Jul-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'relocationOp132Aug',
    name: 'Aug-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'relocationOp132Sep',
    name: 'Sep-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'relocationOp132Oct',
    name: 'Oct-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'relocationOp132Nov',
    name: 'Nov-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  },
  {
    key: 'relocationOp132Dec',
    name: 'Dec-23',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 150 // 默认宽度，像素
  }
]
export const changeRelocationDataYear = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    relocationData.forEach((item) => {
      let name = item.name.split('-')[0]
      item.name = name + '-' + year
    })
  }
}
relocationData.map((v) => {
  v.name = 'Relocation ' + v.name
})
