import layout from '@/layout'

export default {
  path: '/employees',
  component: layout,
  redirect: '/employees/hired',
  name: 'employees',
  meta: {
    title: 'employees',
    icon: 'user'
  },
  children: [
    {
      path: '/employees/hired',
      name: 'personnelRequisition',
      component: () => import('@/views/employee/employees.vue'),
      meta: {
        title: 'employees',
        icon: '#'
      }
    }
  ]
}
