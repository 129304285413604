// 专门处理权限路由的模块
import { publicRoutes } from '@/router/publicRoutes'
import { privateRoutes } from '@/router/privateRoutes'
import { getItem } from '@/utils/storage'
import { POINTLIST } from '@/constant'
const { CommonRouter } = $PCommon
const catch404 = CommonRouter.filter((item) => item.path === '/404')
export default {
  namespaced: true,
  state: {
    // 路由表：初始拥有静态路由权限
    routes: publicRoutes,
    point: getItem(POINTLIST) || []
  },
  mutations: {
    /**
     * 增加路由
     */
    setRoutes(state, newRoutes) {
      // 永远在静态路由的基础上增加新路由
      state.routes = [...publicRoutes, ...newRoutes]
    },
    setPoint(state, point) {
      state.point = point
    }
  },
  actions: {
    /**
     * 根据权限筛选路由
     */
    filterRoutes(context, menus) {
      const routes = []
      console.log(menus)
      // 路由权限匹配
      menus.forEach((key) => {
        // 权限名 与 路由的 name 匹配
        let routesItem = {}
        let childArr = []
        const Proutes = [...privateRoutes]
        Proutes.forEach((privateItem) => {
          if (key.menuUrl === privateItem.path) {
            if (key.children && key.children.length) {
              key.children.forEach((keyChild) => {
                if (privateItem.children && privateItem.children.length) {
                  const privateChildItem = privateItem.children.find((item) => {
                    return item.path.includes(keyChild.menuUrl)
                  })
                  if (privateChildItem) {
                    privateChildItem.meta.pointList = keyChild.children
                    childArr.push(privateChildItem)
                  }
                }
              })
              if (childArr.length) {
                routesItem = privateItem
                routesItem.children = childArr
                routes.push(routesItem)
              }
            }
            childArr = []
            routesItem = {}
          }
        })
      })
      // 最后添加 不匹配路由进入 404
      routes.push({
        path: '/:catchAll(.*)',
        name: '404',
        component: catch404[0].component
      })
      context.commit('setRoutes', routes)
      return routes
    },
    savePont(context, point) {
      context.commit('setPoint', point)
    }
  }
}
