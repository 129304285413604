import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/index.scss'
import '@/router/permission.js'
import InstallDirective from '@/directives/index.js'
import installIcons from '@/icons'
import installEchoFilter from '@/Filter/Echo'
import installElement from '@/plugins/ElementUI'

import '@/plugins/VeeValidate.js'
import '@/plugins/vxe-table'
import apiIndex from '@/api/index'
import i18n from '@/i18n'
const { OneUI } = $PCommon
import Vue from 'vue'
import 'vue-orgchart/dist/style.min.css'
import dayjs from 'dayjs'
const isBetween = require('dayjs/plugin/isBetween')
dayjs.extend(isBetween)

Vue.config.productionTip = false
Vue.use(OneUI)
InstallDirective(Vue)
installIcons(Vue)
installEchoFilter(Vue)
installElement(Vue)
Vue.prototype.$dayjs = dayjs

Vue.prototype.$Api = apiIndex
// 1

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
