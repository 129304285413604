import store from '@/store'

function checkPermission(el, binding) {
  // 获取所有的功能指令
  const points = store.getters.point
  const roleName = el.attributes
  if (points) {
    if (roleName.role) {
      if (roleName.role.nodeValue) {
        const hasPermission = points.some((point) => {
          return point.menuCode === roleName.role.nodeValue
        })
        // 如果无法匹配，则表示当前用户无该指令，那么删除对应的功能按钮

        if (!hasPermission) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      } else {
        throw new Error('权限按钮请绑定role属性')
      }
    } else {
      throw new Error('权限按钮请绑定role属性')
    }
  }
}

export default {
  // 在绑定元素的父组件被挂载后调用
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  // 在包含组件的 VNode 及其子组件的 VNode 更新后调用
  update(el, binding) {
    checkPermission(el, binding)
  }
}
