import Vue from 'vue'
import { check, typeRange } from '@/utils/util'

export default function installEchoFilter(Vue) {
  Vue.filter('echo', function (value, arr) {
    if (!value) return
    let province,
      city,
      cityArr,
      area,
      areaArr,
      valueArr = value.split(',')
    if (arr && check(arr) === typeRange.array) {
      arr.map((item, index) => {
        if (valueArr[0] == item.value) {
          province = item.label
          cityArr = item.children
        }
      })
    }
    if (cityArr && check(cityArr) === typeRange.array) {
      cityArr.map((item, index) => {
        if (valueArr[1] == item.value) {
          city = item.label
          areaArr = item.children
        }
      })
    }
    if (areaArr && check(areaArr) === typeRange.array) {
      areaArr.map((item, index) => {
        if (valueArr[2] == item.value) {
          area = item.label
        }
      })
    }
    return province + city + area
  })
  Vue.filter('interceptTime', function (value) {
    if (value) {
      return value.substring(0, 10)
    }
  })
}
