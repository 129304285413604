import $http, { $httpOrigin } from '@/utils/request'
const url = process.env['VUE_APP_BASE_API']
export default {
  //获得下拉框数据
  getBrandName({ bussinessUnitId }) {
    return $http.get('/hrRafForm/getInternalBrandName', {
      params: {
        bussinessUnitId
      }
    })
  },
  //获得下拉框数据
  getOfferStatusList({}) {
    return $http.get('/offerProposal/offerStatus', {
      params: {}
    })
  },
  //获得下拉框数据
  getNationList({}) {
    return $http.get('/baseData/nation', {
      params: {}
    })
  },
  // 获取员工数据
  getDataList(params) {
    return $http.get('/staff/staffInfo', {
      params
    })
  },
  // 拒绝入职
  rejectJoin(params) {
    return $http.get('/offerProposal/rejectJoin', {
      params
    })
  },
  // 回收
  backEmpoyee(params) {
    return $http.get('/staff/takeBack', {
      params
    })
  },
  // 离职确认
  sureResign(params) {
    return $http.get('/staff/resignConfirm', {
      params
    })
  },
  //获得分配的人
  getAssignUser(params) {
    return $http.get('/staff/getUserList', {
      params
    })
  },
  //分配功能
  assignStaff(params) {
    return $http.get('/staff/distribute', { params })
  },
  //通知IT
  noticeIT(params) {
    return $http.post('/staff/notifyItAdmin', params)
  },
  // 获取详情信息
  getBascMsg({ id }) {
    return $http.get('/staff/basic', {
      params: {
        id
      }
    })
  },
  //获取职位信息
  getPositionMsg({ id }) {
    return $http.get('/staff/job', {
      params: {
        id
      }
    })
  },
  //获取合同信息
  getContactMsg({ id }) {
    return $http.get('/staff/contract', {
      params: {
        id
      }
    })
  },
  getSalaryMsg({ id }) {
    return $http.get('/staff/salary', {
      params: {
        id
      }
    })
  },
  //获取教育信息
  getEducationMsg(params) {
    return $http.get('/staff/education', {
      params: {
        ...params
      }
    })
  },

  //获取离职信息
  getLeaveMsg(params) {
    return $http.get('/staff/resignDetail', {
      params: {
        ...params
      }
    })
  },
  //获取工作经历
  getworkMsg(params) {
    return $http.get('/staff/employmentHistory', {
      params: {
        ...params
      }
    })
  },
  //删除工作经历
  deleteEmploymentHistory(id) {
    return $http.get('/staff/deleteEmploymentHistory', {
      params: id
    })
  },
  //删除教育信息
  deleteEducation(id) {
    return $http.get('/staff/deleteEducation', {
      params: id
    })
  },
  // 编辑教育信息
  editEducation(params) {
    return $http.post('/staff/editEducation', params)
  },
  //编辑工作经历
  editEmploymentHistory(params) {
    return $http.post('/staff/editEmploymentHistory', params)
  },
  //编辑薪水信息
  editSalary(params) {
    return $http.post('/staff/editStaffSalary', params)
  },
  //员工转岗-转岗详情
  detail(params){
    return $http.get('/staff/transfer/detail', { params: { ...params } })
  },
  //员工晋升详情
  promotionDetail(params){
    return $http.get('/staffPromotion/promotion-detail', { params: { ...params } })
  },
  inChange(candidateId){
    return $http.get('/workflow/inChange/' + candidateId)
  },
  editLm(params){
    return $http.post('/staff/position/staffList/editLm', params)
  },
  downloadAllFile(data) {
    window.location = `${url}/api/iris/candidate/downloadBatch?&ids=${data.ids}&token=${localStorage.iriseToken}`
  },
  downloadFile({ type, ids }) {
    // console.log('00000',`/api/iris/staff/staffTemplateDownPackage?flag=${type}&ids=${ids}&token=${localStorage.iriseToken}`)
    // return
    window.location = `${url}/api/iris/staff/staffTemplateDownPackage?flag=${type}&ids=${ids}&token=${localStorage.iriseToken}`
  },
  downloadSingleFile({ type, id }) {
    window.location = `${url}/api/iris/staff/staffTemplateDownload?flag=${type}&id=${id}&token=${localStorage.iriseToken}`
  },
  downloadTrackerData(params) {
    console.log(params)
    window.location = `${url}/api/iris/pctrackerVersion/pcTrackerDownload?versionId=${params.versionId}&assigmentProjectLevel2=${params.assigmentProjectLevel2}
    &businessUnitName=${params.businessUnitName}&chineseName=${params.chineseName}&englishName=${params.englishName}&type=${params.type}&hcNature=${params.hcNature}&localOrExpat=${params.localOrExpat}&team=${params.team}&token=${localStorage.iriseToken}`
  },
  downloadDynamicPctrackerData(params) {
    console.log(params)
    window.location = `${url}/api/iris/dynamicPctracker/download?id=${params.id}&isRfVersion=${params.isRfVersion}&year=${params.year}&token=${localStorage.iriseToken}`
  },
  downloadDynamicPctrackerDataByCEO(params) {
    console.log(params)
    window.location = `${url}/api/iris/dynamicPctracker/ceo/download?id=${params.id}&isRfVersion=${params.isRfVersion}&year=${params.year}&token=${localStorage.iriseToken}`
  },
  downloadDynamicPctrackerDataFileBack(params) {
    console.log(params)
    window.location = `${url}/api/iris/dynamicPctracker/fileBack/download?id=${params.id}&token=${localStorage.iriseToken}`
  },
}
//获取员工流程枚举
export const getStaffFlow = ()=>{
  return $http.get("/enums/list/staffFlow")
}
export const downLoadStaffList = (params) => {
  return $http({
    method:'POST',
    url:'/staff/downLoad/staff_list',
    responseType: 'arraybuffer',
    data:params
  })
}
export const downloadRenewal = (params) => {
  return $http({
    method:'POST',
    url:'/staff/downLoad/renewal_list',
    responseType: 'arraybuffer',
    data:params,
    timeout: 24 * 60 * 60 * 1000
  })
}
