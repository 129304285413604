export const salaryManagementPub = [
  {
    path: '/home/adjustment',
    name: 'adjustment',
    component: () =>
      import(
        '@/views/salaryManagement/components/table/adjustmentMangement.vue'
      ),
    meta: {
      title: '',
      icon: '',
      hidden: true,
      activeMenu: '/pcTracker/pcTracker'
    }
  },
  {
    path: '/home/pcTracker/secondLevelPage',
    name: 'secondLevelPage',
    component: () => import('@/views/salaryManagement/pcTracker.vue'),
    meta: {
      title: '',
      icon: '',
      hidden: true,
      activeMenu: '/pcTracker/pcTracker'
    }
  },
  {
    path: '/pcTracker/editHistory',
    name: 'editHistory',
    component: () =>
      import('@/views/salaryManagement/components/table/editHistory.vue'),
    meta: {
      title: 'editHistory',
      icon: '',
      hidden: true,
      activeMenu: '/pcTracker/pcTracker'
    }
  },
  {
    path: '/pcTracker/IC_ChargeDetail',
    name: 'IC_ChargeDetail',
    component: () =>
      import(
        '@/views/IC_Charge/components/IC_ChargeDetail/IC_ChargeDetail.vue'
      ),
    meta: {
      title: 'IC_ChargeDetail',
      hidden: true,
      activeMenu: '/pcTracker/ICCharge'
    }
  }
]
