import { getItem } from '@/utils/storage'
import { relocationData } from '@/views/salaryManagement/editionData/addColumnConfig/relocationData'

export const othersData = [
  {
    key: 'fellingfeeOthersOp132Jan',
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'fellingfeeOthersOp132Feb',
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'fellingfeeOthersOp132Mar',
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'fellingfeeOthersOp132Apr',
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'fellingfeeOthersOp132May',
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'fellingfeeOthersOp132Jun',
    name: 'Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'fellingfeeOthersOp132Jul',
    name: 'Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'fellingfeeOthersOp132Aug',
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'fellingfeeOthersOp132Sep',
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'fellingfeeOthersOp132Oct',
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'fellingfeeOthersOp132Nov',
    name: 'Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  },
  {
    key: 'fellingfeeOthersOp132Dec',
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, left)
    align: 'left',
    width: 180 // 默认宽度，像素
  }
]
export const changeOthersDataYear = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    othersData.forEach((item) => {
      let name = item.name.split('-')[0]
      item.name = name + '-' + year
    })
  }
}
othersData.map((v) => {
  v.name = 'Filling Fee Others ' + v.name
})
