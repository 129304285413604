import layout from '@/layout'

export default {
  path: '/system',
  component: layout,
  redirect: '/system/secretRoleManage',
  name: 'systemManagement',
  meta: {
    title: 'systemManagement',
    icon: 'systemSetting'
  },
  children: [
    {
      path: '/system/userManage',
      name: 'userManagement',
      component: () => import('@/views/userManage/index'),
      meta: {
        title: 'userManagement',
        icon: '#'
      }
    },
    {
      path: '/system/roleManage',
      name: 'roleManagement',
      component: () => import('@/views/secretRoleManage/secretRoleManage.vue'),
      meta: {
        title: 'roleManagement',
        icon: '#'
      }
    },
    {
      path: '/system/permissionManage',
      name: 'authorityManagement',
      component: () => import('@/views/menuRoleManage/menuRoleManage.vue'),
      meta: {
        title: 'authorityManagement',
        icon: '#'
      }
    },
    {
      path: '/system/codeManagement',
      name: 'codeManagement',
      component: () => import('@/views/secretUserManage/secretUserManage.vue'),
      meta: {
        title: 'codeManagement',
        icon: '#'
      }
    }
  ]
}
