import layout from '@/layout'

export default {
  path: '/org',
  component: layout,
  redirect: '/org/orgManage',
  name: 'baseSetting',
  meta: {
    title: 'baseSetting',
    icon: 'setting'
  },
  children: [
    {
      path: '/org/orgManage',
      name: 'orgManage',
      component: () => import('@/views/orgManage/orgManage.vue'),
      meta: {
        title: 'orgManage',
        icon: '#'
      }
    },
    {
      path: '/org/socialManage',
      name: 'fundsManage',
      component: () => import('@/views/fundsManage/accumulationFunds.vue'),
      meta: {
        title: 'fundsManage',
        icon: '#'
      }
    },
    {
      path: '/org/organization',
      name: 'organization',
      component: () => import('@/views/organization/organization.vue'),
      meta: {
        title: 'organization',
        icon: '#'
      }
    }
  ]
}
