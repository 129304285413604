import request from '@/utils/request'

export const editOpNowSummary = (data) => {
  return request({
    url: '/pctrackerVersion/editOpNowSummary',
    method: 'POST',
    data
  })
}
export const saveOp131 = (data) => {
  return request({
    url: '/dynamicPctracker/op131/save',
    method: 'POST',
    data
  })
}

export const getOpHistorysum = (data) => {
  return request({
    url: '/pctrackerVersion/op111HistorySum',
    method: 'Get',
    params: {
      ...data
    }
  })
}
export const getLoginUserInfo = () => {
  return request({
    url: '/dynamicPctracker/op131/getLoginUserInfo',
    method: 'Get',
  })
}

export const getOpsum = (data) => {
  return request({
    url: '/dynamicPctracker/op111Sum',
    // url: '/pctrackerVersion/op111Sum',
    method: 'Get',
    params: {
      ...data
    }
  })
}

export const gethfmCodes = () => {
  return request({
    url: '/hrRafForm/getEntityHfmCode',
    method: 'Get'
  })
}

export const getLevel2 = (data) => {
  return request({
    url: '/hrRafForm/getLevel2',
    method: 'Get',
    params: {
      ...data
    }
  })
}
export const getDescriptionLevel3 = (data) => {
  return request({
    url: '/hrRafForm/getDescriptionLevel3',
    method: 'Get',
    params: {
      ...data
    }
  })
}

export const saveOp111Remark = (data) => {
  return request({
    url: '/pctrackerVersion/saveOp111Remark',
    method: 'POST',
    data
  })
}

export const getPctrackerVersionHistory = (data) => {
  return request({
    url: '/pctrackerVersion/getHistory',
    method: 'GET',
    params: {
      ...data
    }
  })
}
export const getHcList = (data) => {
  return request({
    url: '/pctrackerVersion/getHcList',
    method: 'GET',
    params: {
      ...data
    }
  })
}
export const hcList = (data) => {
  return request({
    url: '/dynamicPctracker/op131/hcList',
    method: 'GET',
    params: {
      ...data
    }
  })
}
export const getOp131SelectList = (data) => {
  return request({
    url: '/pctrackerVersion/getOp131SelectList',
    method: 'GET',
    params: {
      ...data
    }
  })
}

export const saveHcList = (data) => {
  return request({
    url: '/pctrackerVersion/saveHcList',
    method: 'POST',
    data
  })
}
export const saveOp131List = (data) => {
  return request({
    url: '/pctrackerVersion/saveOp131List',
    method: 'POST',
    data
  })
}

export const deleteOp131Op151Op152ById = (data) => {
  return request({
    url: 'pctrackerVersion/deleteOp131Op151Op152ById',
    method: 'POST',
    data
  })
}
export const getActList = (data) => {
  return request({
    url: '/pctrackerVersion/getLastYearHcList',
    method: 'GET',
    params: {
      ...data
    }
  })
}


export const op131TotalCost = (data) => {
  return request({
    url: '/pctracker-op131-info/op131TotalCost',
    method: 'GET',
    params: {
      ...data
    }
  })
}

export const eleteOp131Op151Op152ById = (data) => {
  return request({
    url: '/pctracker-op131-info/delete',
    method: 'POST',
    data
  })
}
export const accept = (data) => {
  return request({
    url: '/pctracker-op131-info/accept',
    method: 'POST',
    data
  })
}
export const op131Submit = (data) => {
  return request({
    url: '/pctracker-op131-info/submit',
    method: 'POST',
    data
  })
}
export const reject = (data) => {
  return request({
    url: '/pctracker-op131-info/reject',
    method: 'POST',
    data
  })
}

export const getLevel2Orgs = (data) => {
  return request({
    url: '/sysOrg/getLevel2',
    method: 'Get',
    params: {
      ...data
    }
  })
}

export const addRecord = (data) => {
  return request({
    url: '/pctracker-op131-info/save',
    method: 'POST',
    params: {
      ...data
    }
  })
}

export const updateRecord = (data) => {
  return request({
    url: '/pctracker-op131-info/update',
    method: 'POST',
    params: {
      ...data
    }
  })
}

export const getOp131List = (data) => {
  return request({
    url: '/dynamicPctracker/op131/list',
    // url: '/pctracker-op131-info/list',
    method: 'Get',
    params: {
      ...data
    }
  })
}
export const getOp131One = (data) => {
  return request({
    url: '/dynamicPctracker/op131/get',
    method: 'Get',
    params: {
      ...data
    }
  })
}
export const deleteOp131 = (data) => {
  return request({
    url: '/dynamicPctracker/op131/delete',
    method: 'POST',
    data
  })
}
export const getHisOp131List = (data) => {
  return request({
    url: '/pctracker-op131-info/historyList',
    method: 'Get',
    params: {
      ...data
    }
  })
}
export const saveIcCharge = (data) => {
  return request({
    url: '/pctracker-op131-info/saveIcCharge',
    method: 'POST',
    data
  })
}
export const getPeriodList = (data) => {
  return request({
    url: '/pctracker-op131-info/getPeriodList',
    method: 'GET',
    params: {
      ...data
    }
  })
}
export const savePeriodData = (data) => {
  return request({
    url: '/pctracker-op131-info/savePeriodData',
    method: 'POST',
    data
  })
}
export const deletePeriod = (data) => {
  return request({
    url: '/pctracker-op131-info/deletePeriod',
    method: 'POST',
    data
  })
}
export const getOp111List = (data) => {
  return request({
    url: '/pctracker-op131-info/getOp111List',
    method: 'GET',
    params: {
      ...data
    }
  })
}
export const getIcChargeOp131List = (data) => {
  return request({
    url: '/pctracker-op131-info/getOp131List',
    method: 'GET',
    params: {
      ...data
    }
  })
}
export const getVersionDetail = (id) => {
  return request({
    url: '/pctracker-op131-info/versionDetail',
    method: 'GET',
    params: {
      id: id
    }
  })
}
export const getEntityHfmCode = () => {
  return request({
    url: '/hrRafForm/getEntityHfmCode',
    method: 'GET',
    params: {
    }
  })
}
export const getOrganizationsUser = () => {
  return request({
    url: '/sysUser/getOrganizationsUser',
    method: 'GET',
    params: {
      hfmCode: '',
      userName: '',
      userType: 'CEO',
    }
  })
}