import { getItem } from '@/utils/storage'
import { thirteenSalaryData } from '@/views/salaryManagement/editionData/addColumnConfig/thirteenSalaryData'

export const HCData = [
  {
    key: 'hcJan',
    name: 'Jan-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hcFeb',
    name: 'Feb-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hcMar',
    name: 'Mar-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hcApr',
    name: 'Apr-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hcMay',
    name: 'May-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hcJun',
    name: 'Jun-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hcJul',
    name: 'Jul-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hcAug',
    name: 'Aug-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hcSep',
    name: 'Sep-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hcOct',
    name: 'Oct-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hcNov',
    name: 'Nov-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  },
  {
    key: 'hcDec',
    name: 'Dec-',
    dataType: 'number',
    visible: true,
    sortable: false,
    fixed: false, // 固定列的位置(left, right)
    align: 'right',
    width: 100 // 默认宽度，像素
  }
]
export const changeHCDataYear = () => {
  let year = getItem('indexYear__yc')
  if (year) {
    HCData.forEach((item) => {
      let name = item.name.split('-')[0]
      item.name = name + '-' + year
    })
  }
}
HCData.map((v) => {
  v.name = 'HC ' + v.name
})
